.corporate-video-component {
  .card-header-style {
    background: var(--main-theme-color-one);
    color: white;
  }
  .video-container {
    // box-shadow: 0px 0px 12px -10px #000;
    .video-style {
      width: 100%;
      height: 300px;
    }
    .card-body-custom {
      background-color: ghostwhite;
    }
  }
}
