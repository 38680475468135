.enquiry-select {
  .city-destination__control {
    min-height: 55px;
  }
  .hotel-category__control {
    min-height: 55px;
  }
  .occasion-control__control {
    min-height: 55px;
  }
  .city-destination__menu {
    z-index: 2;
  }

  .hotel-category__menu {
    z-index: 2;
  }

  .occasion-control__menu {
    z-index: 2;
  }
}

.talk-to-experts {
  .slot-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .time-slot {
      max-width: 100px;
      min-width: 100px;
      padding: 8px;
      text-align: center;
      border-radius: 3px;
      margin: 5px;
    }
    .time-slot-disabled {
      border: 1px solid #a0a096;
      background-color: transparent;
      color: black;
      cursor: default;
    }
    .time-slot-booked {
      // border: 1px solid white;
      background-color: #8ec549;
      color: white;
      cursor: default;
    }
    .time-slot-available {
      border: 1px solid #8ec549;
      background-color: #8ec549;
      color: black;
      cursor: pointer;
      &:hover {
        color: white;
        // border: 1px solid white;
      }
    }
    .time-slot-busy {
      border: 1px solid #eb525b;
      background-color: #eb525b;
      color: white;
      cursor: default;
    }
  }
}
