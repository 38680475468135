.review-page {
  .attraction-select-container {
    .attraction-select__control {
      height: 55px;
    }
  }

  .dropzone {
    padding: 25px;
    border: 1px dashed #ced4da;
    border-radius: 0.375px;
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2;
    border: 1px solid #eaeaea;
    margin-bottom: 8;
    margin-right: 8;
    width: 100;
    height: 100;
    padding: 4;
    box-sizing: border-box;
  }

  .thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
  }

  .thumb-img {
    display: block;
    width: 150px;
    height: 100px;
  }
}
