.sign-in {
  a,
  i,
  span {
    font-size: 16px;
    color: #808080;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;
    &:hover {
      color: var(--color-white);
    }
  }
  &:hover {
    a,
    i,
    span {
      font-size: 16px;
      color: var(--color-white);
      font-weight: 500;
      margin-bottom: 0;
      cursor: pointer;
    }
    color: var(--color-white);
  }
}
.profile-menu-content {
  margin-left: -12px !important;
  ul {
    padding: 0px;
  }
  .separator {
    border-top: solid 1px #eeeeee;
  }
  .menu-item {
    padding: 10px 10px;
    &:hover {
      background: var(--main-theme-color-one);
      color: var(--color-white);
      cursor: pointer;
    }
  }

  .testimonial-icon {
    font-size: 18px;
  }
}
