.top-header {
  background-color: #f2f2f2;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  ul {
    margin-bottom: 0px;
    li {
      list-style: none;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;

      .divider {
        height: 18px;
        background: #d4d4d4;
        width: 2px;
        top: 12px;
        position: absolute;
      }
    }
  }
}
