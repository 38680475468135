* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

body,
html {
  height: 100%;
  margin: 0;
}

body {
  background: #fff none repeat scroll;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  position: relative;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

:root {
  --main-theme-color-one: #014073;
  --main-theme-color-two: #ff9d34;
  --paragraph-color: #777c87;
  --color-white: #fff;
}

ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul {
  margin: 0;
  padding: 0;
}

a,
a > * {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: var(--main-theme-color-one);
}

a:hover {
  text-decoration: none;
}

/*--------------------------
            2.  Typography
  ---------------------------*/

h1 {
  font-size: 45px;
  color: #fff;
  line-height: 60px;
  letter-spacing: 0.25px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin: 0;
}

h2 {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: var(--main-theme-color-one);
  margin: 0;
  letter-spacing: 0.25px;
  line-height: 48px;
}

h3 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #272063;
  font-weight: 400;
  letter-spacing: -0.36px;
}

h4 {
  font-size: 30px;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0;
  color: #272063;
}

h5 {
  font-size: 24px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: var(--main-theme-color-one);
  font-weight: bold;
}

h6 {
  font-size: 20px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: var(--main-theme-color-one);
  font-weight: 400;
  letter-spacing: 0.25px;
}

p {
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
  color: var(--paragraph-color);
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.25px;
}

/*---------------------------------------
      3.content-box
  -----------------------------------------*/

.content-box {
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

.content-box h2 {
  margin-top: 10px;
}

.content-box h2 span {
  color: var(--main-theme-color-two);
}

.content-box p {
  margin-top: 10px;
  padding: 0 32%;
}

/*---------------------------------------
          4.common-banner
  -----------------------------------------*/

.common-banner-text {
  text-align: center;
}

.commom-sub-heading h6 a {
  color: #fff;
}

.commom-sub-heading h6 a:hover {
  color: var(--main-theme-color-two);
}

.commom-sub-heading h6 {
  margin-top: 15px;
  font-size: 22px;
}

.commom-sub-heading h6 span {
  color: #fff;
}

/*--------------------------------------
      5.Button css
  ---------------------------------------*/

.btn-1 {
  color: #fff;
  background: var(--main-theme-color-two);
  padding: 10px 15px;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 1.2s;
}

.btn-1:hover {
  color: var(--color-white);
}

.btn-1:after {
  content: "";
  position: absolute;
  background: #fff;
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 1.1s;
}

.btn-1:hover:after {
  width: 100%;
}

.btn-2 {
  color: #fff !important;
  background: var(--main-theme-color-two) !important;
  padding: 10px 15px;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 1.2s;
}

.btn-2:hover {
  color: #fff;
}

.btn-2:after {
  content: "";
  position: absolute;
  background: var(--main-theme-color-one);
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 1.1s;
  border-radius: 3px;
}

.btn-2:hover:after {
  width: 100%;
  border-radius: 3px;
}

.for-pag-btn {
  color: #fff;
  background: var(--main-theme-color-one);
  padding: 12px 30px;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
}

.for-pag-btn:hover {
  color: #fff;
}

.for-pag-btn:after {
  content: "";
  position: absolute;
  background: var(--main-theme-color-two);
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
  border-radius: 3px;
}

.for-pag-btn:hover:after {
  width: 100%;
  border-radius: 3px;
}

.btn-3 {
  color: #fff;
  background: var(--main-theme-color-two);
  padding: 10px 15px;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
}

.btn-3:hover {
  color: #fff;
}

.btn-3:after {
  content: "";
  position: absolute;
  background: var(--main-theme-color-one);
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
}

.btn-3:hover:after {
  width: 100%;
}

.btn-4 {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  padding: 7px 25px;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
}

.btn-4:hover {
  color: #fff;
  border: 1px solid var(--main-theme-color-one);
  border-radius: 2px;
}

.btn-4:after {
  content: "";
  position: absolute;
  background: var(--main-theme-color-one);
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
}

.btn-4:hover:after {
  width: 100%;
}

.submit {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  padding: 7px 25px;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 500;
  transition: 1.2s;
  background: var(--main-theme-color-one);
}

.submit:hover {
  color: #fff;
}

.submit:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 1.1s;
  background: var(--main-theme-color-two);
}

.submit:hover:after {
  width: 100%;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.mar-top {
  margin-top: 40px;
}

/*6.Home*/
/*--------------------------------------
          6.1  top hedear
  ---------------------------------------*/

.header-most-top {
  background: #000;
  padding: 10px 0px;
}

.main-email-text,
.right-separator {
  border-right: 1px solid #808080;
}

.main-email-text i {
  color: #808080;
  line-height: 30px;
  margin-right: 9px;
  font-size: 16px;
}

.main-email-text p {
  font-size: 14px;
  color: #808080;
  font-weight: 500;
  margin-right: 17px;
  margin-bottom: 0;
}
.header-most-top p {
  margin-bottom: 0;
}

.main-loc-text i {
  color: #808080;
  line-height: 30px;
  margin-right: 9px;
  font-size: 16px;
  margin-left: 17px;
}

.main-loc-text p,
.main-loc-text span {
  font-size: 14px;
  color: #808080;
  font-weight: 500;
  margin-bottom: 0;
  cursor: pointer;
}

.seclict-area img {
  width: 17%;
}

.phone-number-idel h6 a {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  margin-top: 13px;
}

.left-menu-pho {
  background: var(--main-theme-color-two);
  width: 608px;
  position: absolute;
  right: 0;
  display: flex;
  height: 84px;
  border-left: 30px solid var(--main-theme-color-one);
  border-bottom: 85px solid var(--main-theme-color-two);
}

.icon-phon-men i {
  color: var(--main-theme-color-one);
  font-size: 28px;
  background: white;
  height: 48px;
  width: 48px;
  text-align: center;
  line-height: 46px;
  border-radius: 53px;
  position: relative;
  right: 38px;
  top: 17px;
}

.phone-number-idel {
  position: relative;
  right: 25px;
  top: 20px;
}

.phone-number-idel p {
  font-size: 14px;
  color: #603507;
  font-weight: 400;
}

.header-most-top select {
  background: transparent;
  color: #808080;
  width: 66%;
  font-size: 14px;
}

.login-area {
  display: flex;
  float: right;
}

.user-log i {
  color: grey;
  font-size: 16px;
  margin-right: 5px;
  margin-left: 20px;
}

.user-log a {
  font-size: 14px;
  color: #808080;
  font-weight: 500;
}

.user-log {
  border-left: 1px solid #808080;
}

/*--------------------------------------
            6.2  Navber
  ---------------------------------------*/
.site-header {
  position: relative;
  width: 100%;
  z-index: 99;
  top: 0px;
  transition: all 0.3s;
  background-color: var(--main-theme-color-one);
}

@media only screen and (max-width: 1199px) {
  .site-header {
    position: relative;
    top: 0;
  }
}

.site-header .site-navigation .container-fluid {
  padding: 0 80px;
}

.site-header .site-navigation .navigation-area {
  padding: 0;
  justify-content: space-between;
}

// @media only screen and (max-width: 1199px) {
//   .site-header .site-navigation {
//     display: none;
//   }
// }

.site-header .navigation-area {
  -moz-box-align: center;
  -moz-box-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 70px;
}

.site-header .navigation-area .site-branding {
  margin-right: 30px;
}

.site-header .navigation-area .site-branding a {
  display: inline-block;
}

.site-header .navigation-area .header-navigation-right {
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.site-header .navigation-area .header-navigation-right .contact-info > a:hover {
  color: #fff;
}

// @media only screen and (max-width: 1199px) {
//   .site-header .navigation-area {
//     display: none;
//   }
// }

.site-header #onePageMenu .menu > ul > li > a {
  margin: 0 12px;
}

/*-------------------------------------------------
      [ ## Sticky header ]
  */
.site-header.sticky-active {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: black;
  box-shadow: 0px 5px 10px -5px rgba(228, 41, 72, 0.15);
}

.site-header.sticky-active .site-navigation {
  position: relative;
}

.site-header.sticky-active .site-navigation .container-fluid {
  padding: 0 80px;
}

.site-header.sticky-active .site-navigation .navigation-area {
  justify-content: space-between;
  padding: 15px 0;
}

.site-header.sticky-active .menu > ul > li > ul.dropdown-content:after {
  height: 20px;
  top: -20px;
}

.site-header.sticky-active
  .menu
  > ul
  > li.dropdown-trigger:hover
  > ul.dropdown-content {
  top: calc(100% + 20px);
}

/*--------------------------------------------------------------
  # Navigation
  --------------------------------------------------------------*/
/*-------------------------------------------------
      [ ## Links ]
  */
a {
  color: inherit;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}

/*-------------------------------------------------
      [ ## Menus ]
  */
.menu ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}

.menu ul li {
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.menu ul li a {
  font-size: 16px;
  font-weight: normal;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  position: relative;
  text-decoration: none;
}

.menu ul li ul.dropdown-content {
  margin-left: 0;
}

.menu ul li ul.dropdown-content li {
  padding: 0;
  display: block;
}

.menu ul li ul.dropdown-content li a {
  display: block;
  padding: 7px 0px;
  color: white;
  text-transform: capitalize;
  -webkit-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
  font-size: 16px;
  font-weight: 500;
}

.menu > ul > li:hover > a,
.menu ul li > a.active,
.menu ul li.current > a {
  color: var(--main-theme-color-two) !important;
}

.menu > ul > li:hover > a:before,
.menu ul li > a.active:before,
.menu ul li.current > a:before {
  width: 100%;
  left: 0;
  right: auto;
}

.menu > ul > li {
  margin: 0 15px;
}

.menu > ul > li > a {
  padding: 15px 0px;
  font-weight: 500;
  position: relative;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.075em;
  font-size: 17px;
}

.menu > ul > li:first-child {
  margin-left: 0px;
}

.menu > ul > li:last-child {
  margin-right: 0px;
}

.menu > ul > li > ul.dropdown-content {
  background-color: var(--main-theme-color-one);
  position: absolute;
  width: 170px;
  padding: 15px 20px;
  left: 0;
  top: calc(100% + 50px);
  z-index: 999;
  visibility: hidden;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  opacity: 0;
  z-index: 5;
}

.menu > ul > li > ul.dropdown-content:before {
  border-color: transparent transparent var(--main-theme-color-one);
  border-style: solid;
  border-width: 11px 7px;
  content: "";
  left: 22px;
  position: absolute;
  top: -22px;
}

.menu > ul > li > ul.dropdown-content > li:hover > a {
  color: var(--main-theme-color-two) !important;
  padding-left: 5px;
}

.menu > ul > li > ul.dropdown-content:after {
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: -15px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.menu > ul > li.dropdown-trigger > a:after {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  padding-left: 5px;
  opacity: 0.5;
  font-weight: 800;
}

.menu > ul > li.dropdown-trigger:hover > ul.dropdown-content {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 15px);
}

.menu > ul > li.dropdown-trigger.mega-menu {
  position: inherit;
}

.menu > ul > li.dropdown-trigger.mega-menu:hover .mega-menu-content {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

ul.dropdown-content > .dropdown-trigger {
  position: relative;
}

ul.dropdown-content > .dropdown-trigger > a {
  position: relative;
}

ul.dropdown-content > .dropdown-trigger > a:after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0px;
  top: 8px;
  font-size: 16px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 0.5;
  font-weight: 800;
}

ul.dropdown-content > .dropdown-trigger ul.dropdown-content {
  left: calc(100% + 20px);
  top: -5px;
  visibility: hidden;
  background-color: var(--main-theme-color-one);
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  position: absolute;
  width: 200px;
  z-index: 999;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  padding: 7px 15px;
}

ul.dropdown-content > .dropdown-trigger:hover > a:after {
  right: -2px;
  opacity: 1;
}

ul.dropdown-content > .dropdown-trigger:hover > ul.dropdown-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

ul.dropdown-content
  > .dropdown-trigger:hover
  > ul.dropdown-content
  > li:last-child {
  border-bottom: 0px solid #e5e5e5;
}

ul.dropdown-content
  > .dropdown-trigger:hover
  > ul.dropdown-content
  > li:hover
  > a {
  color: var(--main-theme-color-two);
  padding-left: 5px;
}

@media only screen and (max-width: 1620px) {
  .mega-menu-content {
    left: 0px;
    width: calc(100vw - 30px);
  }
}

/* [ ### Mobile Menu ] */
.mobile-menu-area {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .mobile-menu-area {
    display: block;
  }
  .main-email-text p,
  .main-loc-text p {
    display: none;
  }
}

.mean-container {
  position: relative;
}

.mean-container .mean-bar {
  min-height: 75px;
  background-color: transparent;
  z-index: 9999;
}

.mean-container a.meanmenu-reveal {
  padding: 22px 0;
  right: 0px !important;
  width: 30px;
}

.mean-container a.meanmenu-reveal span {
  background-color: #fff;
  height: 2px;
}

.mean-container a.meanmenu-reveal i {
  color: #fff;
  display: block;
  font-size: 20px;
  margin-top: 8px;
}

.mean-container a.meanmenu-reveal.meanclose + .mean-nav {
  margin-top: 70px;
  padding-bottom: 0px;
}

.mean-container .mean-nav {
  margin-top: 55px;
  background: transparent;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.mean-container
  .mean-nav
  ul.dropdown-content
  > .dropdown-trigger
  ul.dropdown-content {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
  top: 0px;
  border-top: 0px solid;
}

.mean-container .mean-nav ul li {
  float: none;
}

.mean-container .mean-nav ul li.dropdown-trigger:hover > a {
  color: #fff;
  padding-left: 5%;
}

.mean-container .mean-nav ul li a.mean-expand {
  height: 55px;
  padding: 0 !important;
  width: 100%;
}

.mean-container .mean-nav ul li a.mean-expand:before {
  position: absolute;
  content: "\f0da";
  top: 0;
  right: -5px;
  width: 30px;
  height: 100%;
  text-align: center;
  font-family: "Font Awesome 5 Free";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 18px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.mean-container .mean-nav ul li a.mean-expand > span {
  display: none;
}

.mean-container .mean-nav ul li a.mean-clicked:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mean-container .mean-nav ul li a {
  text-transform: capitalize;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--main-theme-color-two);
}

.mean-container .mean-nav ul li li a {
  padding: 15px 25px;
  text-transform: capitalize;
  width: calc(100% - 50px);
}

.mean-container .mean-nav ul li li a:hover {
  padding-left: 30px !important;
}

.mean-container ul.dropdown-content > .dropdown-trigger > a:after {
  content: "";
}

.mean-container ul.dropdown-content > .dropdown-trigger ul.dropdown-content {
  left: 0 !important;
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  position: relative;
  width: 100%;
  background-color: transparent;
  padding: 0;
}

.mean-container
  ul.dropdown-content
  > .dropdown-trigger
  ul.dropdown-content
  > li
  > a:hover {
  padding-left: 30px;
}

.mean-container
  ul.dropdown-content
  > .dropdown-trigger
  > ul.dropdown-content
  > li
  > a,
.mean-container
  ul.dropdown-content
  > .dropdown-trigger:hover
  > ul.dropdown-content
  > li:hover
  > a {
  padding: 12px 40px;
  width: calc(100% - 80px);
}

.mean-container
  ul.dropdown-content
  > .dropdown-trigger:hover
  > ul.dropdown-content
  > li {
  border-bottom: 0px solid #e5e5e5;
}

.mean-container .mean-nav > ul > li > ul > li a.mean-expand {
  height: 45px;
  padding: 0px !important;
}

@media only screen and (max-width: 1199px) {
  .header-navigation-right .contact-info {
    display: none;
  }

  .header-navigation-right .search-wrap {
    position: absolute;
    top: 23px;
    right: 50px;
    margin-left: 0;
    z-index: 999999;
  }

  .header-navigation-right .search-wrap .search-btn {
    font-size: 16px;
    width: 35px;
    height: 35px;
    line-height: 33px;
  }

  .header-navigation-right .search-wrap .search-form {
    top: calc(100% + 23px);
  }

  .mobile-menu {
    display: block;
  }

  .mobile-menu .mobile-logo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0px;
    top: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 66px;
    z-index: 9999;
  }

  .mobile-menu .menu-label {
    display: none;
  }

  // .site-navigation {
  //   display: none;
  // }

  .mean-container .mean-bar {
    float: none;
  }

  .mean-container .mean-bar .mean-nav {
    float: none;
  }

  .mean-container .mean-nav ul li a {
    color: #000;
    display: block;
    float: none;
    font-size: 16px;
    padding: 15px 0px;
    width: auto;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border-top: 1px solid #262626;
  }

  .mean-container .mean-nav ul li a:hover {
    padding-left: 7px;
  }

  .mean-container
    .mean-nav
    #nav
    > li.dropdown-trigger
    > .dropdown-content
    > li.dropdown-trigger:hover
    > a {
    padding-left: 30px !important;
  }

  .mean-container
    .mean-nav
    #nav
    > li.dropdown-trigger
    > .dropdown-content
    > li.dropdown-trigger
    > .dropdown-content
    > li:hover
    > a {
    padding-left: 45px !important;
  }

  .mean-container
    .mean-nav
    #nav
    > li.dropdown-trigger
    > .dropdown-content
    > li.dropdown-trigger
    .mean-expand {
    height: 54px;
    padding-left: 0 !important;
  }

  .mean-container
    .mean-nav
    #nav
    > li.dropdown-trigger
    > .dropdown-content
    > li.dropdown-trigger
    .mean-expand.mean-clicked:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .mega-menu-content {
    background-color: transparent;
    left: 0;
    padding: 0px 20px 25px;
    position: relative;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: calc(100% - 40px);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07);
  }

  .mega-menu-content .cat-item {
    background-color: transparent;
    border-top: 0 none !important;
    padding: 0 !important;
  }
}

/*--------------------------------------
      6.3. banner-Home
  ---------------------------------------*/

#banner-home-slider {
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.banner-text-home h1 {
  margin-top: 25px;
}

.banner-text-home h6 {
  color: var(--main-theme-color-two);
}

.banner-text-home p {
  color: #fff;
  margin-top: 15px;
}

.button-common {
  margin-top: 45px;
}

#banner-home {
  background-image: url(/assets/img/common-img/banner-img.png);
  padding: 170px 0 290px 0;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.banner-text-home h1 {
  margin-top: 25px;
}

.banner-text-home h6 {
  color: var(--main-theme-color-two);
}

.banner-text-home p {
  color: #fff;
  margin-top: 15px;
}

.button-common {
  margin-top: 45px;
}

.video-play-banner {
  float: right;
  margin-top: 130px;
}

.video-play-banner i {
  color: var(--main-theme-color-two);
  font-size: 28px;
  height: 90px;
  width: 90px;
  background: #fff;
  line-height: 90px;
  border-radius: 72%;
}

.video-area.style-two:after {
  position: absolute;
  left: 0;
  top: 0;
  // background-image: url(/assets/img/bg/video-area-bg.png);
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
}

.img-with-video .img-wrap .hover .video-play-btn:before {
  width: 90px;
  height: 90px;
}

/*-------------------------------------------
        6.4. video-play animation	
   ---------------------------------------*/

.video-play-btn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}

.video-play-btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 16px;
}

.video-play-btn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 45%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #2f7baf;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}

.video-play-btn:hover {
  color: #313131;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}

.vbox-close {
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 32%;
  width: 50px;
  height: 40px;
  padding: 6px;
  display: block;
  background-position: 10px center;
  overflow: hidden;
  font-size: 40px;
  line-height: 1;
  text-align: center;
  z-index: 99;
}

#faqws-area {
  padding: 100px 0;
}

/*--------------------------------------
              6.5About section home
  -------------------------------------*/

#ab-home {
  padding: 100px 0;
}

#ab-home .nav-fill {
  width: 30%;
}

#ab-home .nav-tabs .nav-link.active {
  color: #fff;
  background-color: var(--main-theme-color-two);
  border-color: transparent;
  border-radius: 0;
}

#ab-home .nav-tabs .nav-link:focus,
#ab-home .nav-tabs .nav-link:hover {
  border-color: transparent;
}

#ab-home select {
  background: #fff;
  padding: 6px 0px 6px 50px;
  height: 45px;
  width: 100%;
  color: var(--main-theme-color-one);
  font-size: 16px;
  border: 1px solid;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 5px;
}

#ab-home .nav-tabs .nav-link {
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
}

.nav-tabs {
  border-bottom: none;
}

.booking-info .sec-po {
  position: absolute;
  left: 29px;
  top: 14px;
  font-size: 16px;
  color: var(--main-theme-color-one);
}

//   label {
//     margin-left: 20px;
//   }

.booking-info #datepicker {
  width: 100%;
}

.booking-info #datepicker-2 {
  height: 45px;
  width: 100%;
}

.booking-info #datepicker-3 {
  height: 50px;
  width: 100%;
}

.booking-info #datepicker-4 {
  height: 50px;
  width: 100%;
}

#datepicker-2 > span {
  position: absolute;
  left: 18px;
  z-index: 9;
  color: var(--main-theme-color-one);
}

#datepicker-3 > span {
  position: absolute;
  left: 18px;
  z-index: 9;
  color: var(--main-theme-color-one);
}

#datepicker-4 > span {
  position: absolute;
  left: 18px;
  z-index: 9;
  color: var(--main-theme-color-one);
}

#datepicker-2 > span:hover {
  cursor: pointer;
}

#datepicker-3 > span:hover {
  cursor: pointer;
}

#datepicker-4 > span:hover {
  cursor: pointer;
}

#datepicker-2 .form-control {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0 50px;
  height: 45px;
}

#datepicker-3 .form-control {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0 50px;
  height: 45px;
}

#datepicker-4 .form-control {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0 50px;
  height: 45px;
}

#datepicker > span {
  position: absolute;
  left: 18px;
  z-index: 9;
  color: var(--main-theme-color-one);
}

#datepicker > span:hover {
  cursor: pointer;
}

#datepicker .form-control {
  border: 1px solid #000;
  padding: 0 50px;
  height: 45px;
  border-radius: 5px;
}

#datepicker .form-control::placeholder {
  color: var(--main-theme-color-one);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

#datepicker-2 .form-control::placeholder {
  color: var(--main-theme-color-one);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

#datepicker-3 .form-control::placeholder {
  color: var(--main-theme-color-one);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

#datepicker-4 .form-control::placeholder {
  color: var(--main-theme-color-one);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.all-class {
  display: flex;
}

.bugest-info {
  margin-top: 45px;
}

.bugest-info span {
  color: var(--main-theme-color-one);
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.book-ctn {
  // margin-top: 50px;
  // float: right;
}

#nav-tabContent {
  margin: 25px 90px 0 90px;
}

.main-com {
  box-shadow: 0px 0px 36px -5px #0000001a;
  -webkit-box-shadow: 0px 0px 36px -5px #0000001a;
  -ms-box-shadow: 0px 0px 36px -5px #0000001a;
  padding: 0 0 40px 0px;
  margin-top: -200px;
  background: #fff;
}

.left-side-text-ab h2 {
  margin-top: 10px;
}

.left-side-text-ab span {
  color: var(--main-theme-color-two);
}

.left-side-text-ab p {
  margin-top: 15px;
}

.left-side-text-ab {
  margin-top: 100px;
}

#ab-home {
  background-image: url(/assets/img/common-img/map.png);
  background-size: cover;
  z-index: 111;
  position: relative;
}

.ab-slider {
  margin-top: 100px;
}

#ab-home .owl-carousel .owl-nav.disabled {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
}

#ab-home .owl-theme .owl-nav.disabled + .owl-dots {
  display: none;
}

#ab-home .owl-carousel .owl-nav button.owl-next {
  color: #fff;
  background: var(--main-theme-color-two);
  padding: 11px 13px !important;
  font-size: 22px;
  border-radius: 0;
  border: none;
  margin: 0;
}

#ab-home .owl-carousel .owl-nav button.owl-prev {
  color: #fff;
  background: var(--main-theme-color-two);
  padding: 11px 13px !important;
  font-size: 22px;
  border-radius: 0;
  border: none;
  margin: 0;
}

#ab-home .owl-carousel .owl-nav button.owl-prev:hover {
  background: var(--main-theme-color-one);
}

#ab-home .owl-carousel .owl-nav button.owl-next:hover {
  background: var(--main-theme-color-one);
}

.map-io {
  position: absolute;
  right: 45px;
  top: 15px;
  font-size: 18px;
  z-index: 999;
}

.seclict-area {
  position: relative;
}

.seclict-area i {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 5px;
  color: #808080;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

#header-most-top select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.pad {
  padding: 7px 25px !important;
}

.amr {
  margin-right: 10px;
}

.buget {
  width: 36%;
  height: 6px;
  background: var(--main-theme-color-two);
  position: absolute;
  top: 55px;
  left: 158px;
}

.ranger-1 {
  height: 20px;
  width: 20px;
  background: #0c4573;
  position: absolute;
  border-radius: 57px;
  top: -6px;
}

.ranger-2 {
  height: 20px;
  width: 20px;
  background: #0c4573;
  position: absolute;
  border-radius: 57px;
  top: -6px;
  right: 0;
}

/*--------------------------------------
              6.Amazing section
  -------------------------------------*/

#amazing {
  padding: 100px 0 0 0;
  background-image: url(/assets/img/common-img/about-bg.png);
  background-size: cover;
  background-position: center;
}

.amz-img img {
  width: 100%;
}

.mar-p {
  margin-right: 70px;
}

.all-service-travel {
  display: flex;
  position: relative;
  margin-top: 215px;
}

.flight-cover {
  background: var(--main-theme-color-one);
  height: 80px;
  width: 80px;
  text-align: center;
  padding-top: 16px;
  margin-right: 30px;
  border-radius: 57px;
  transition: 1.4s;
}

.flight-cover:hover {
  background: var(--main-theme-color-two);
  transform: translateY(-10px);
}

.flight-cover:hover i {
  transform: translateY(-3px);
}

.flight-cover i {
  font-size: 28px;
  color: #fff;
  transition: 1.2s;
}

.flight-cover p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 0;
  margin-top: 12px;
  color: #fff;
}

.grun-img {
  position: absolute;
  top: 36%;
  left: 93px;
}

.grun-img img {
  width: 100%;
}

.shape-3 {
  -webkit-animation: left3right infinite linear 0s infinite;
  animation: left3right 60s linear 0s infinite;
}

@-webkit-keyframes left3right {
  100% {
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg);
  }
}

.mar-o {
  margin-top: 0;
}

/*--------------------------------------
                  7.Tour area
  -------------------------------------*/

#tour-des {
  padding: 60px 0;
}

.des-img {
  overflow: hidden;
}

.des-img img {
  width: 100%;
  transition: 2s;
  height: 230px;
}

.des-cov:hover .des-img img {
  transform: scale(1.1);
  opacity: 0.9;
}

.dayt {
  display: flex;
  justify-content: space-between;
}

.dayt p {
  color: var(--main-theme-color-two);
  font-size: 16px;
  font-weight: 500;
}

.dayt h6 a {
  font-weight: 600;
  color: var(--main-theme-color-one);
  transition: 1.1s;
}

.des-cov:hover .dayt h6 a {
  color: var(--main-theme-color-two);
}

.des-cov-1:hover .dayt h6 a {
  color: var(--main-theme-color-two);
}

.des-para {
  padding: 30px 20px;
  padding-bottom: 0;
}

.des-button-icon {
  display: flex;
  justify-content: space-between;
  position: absolute;
  // bottom: 50px;
  width: calc(100% - 30px);
}

.real-dat-para p {
  font-size: 15px;
  line-height: 24px;
  margin-top: 15px;
}

.start-icon-des i {
  color: var(--main-theme-color-two);
  font-size: 16px;
  margin-right: 5px;
}

.start-icon-des {
  margin-top: -5px;
}

.des-cov {
  height: calc(100% - 20px);
  box-shadow: 0px 0px 12px -10px #000;
  padding-bottom: 70px;
  position: relative;
}
.slider-des {
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      padding: 10px;
      > div {
        height: 100%;
        position: relative;
      }
    }
  }
}
#tour-des .owl-carousel .owl-dots.disabled {
  display: block;
}

#tour-des .owl-theme .owl-dots .owl-dot.active span {
  background: var(--main-theme-color-one);
}

#tour-des .owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 5px 7px;
  background: var(--main-theme-color-two);
}

#tour-des .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color-one);
}

#tour-des .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 40px;
}

/*--------------------------------------
                  8.Summery area
  -------------------------------------*/

#summery {
  background-image: url(/assets/img/common-img/summer.png);
  background-size: cover;
  padding: 100px 0;
}

.summery-cover h6 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 2px;
  font-family: "Lato", sans-serif;
}

.summery-cover p {
  color: #fff;
  // font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-top: 25px;
  margin-bottom: 25px;
}

.summery-cover p span {
  color: var(--main-theme-color-two);
}

.summery-cover h2 {
  color: #fff;
  margin-top: 20px;
  font-weight: 400;
}

.summery-cover {
  text-align: center;
}

.all-spance {
  text-align: center;
  margin-top: 15px;
}

.all-spance span {
  color: #fff;
  font-size: 16px;
}

.all-space-to {
  border: 2px solid white;
  padding: 50px 0;
  position: relative;
}

.all-space-to:after {
  content: "";
  position: absolute;
  top: -8px;
  border-left: 15px solid var(--main-theme-color-two);
  height: 80px;
  border-top: 15px solid var(--main-theme-color-two);
  width: 80px;
  left: -9px;
}

.all-space-to:before {
  content: "";
  position: absolute;
  bottom: -8px;
  border-right: 15px solid var(--main-theme-color-two);
  height: 80px;
  border-bottom: 15px solid var(--main-theme-color-two);
  width: 80px;
  right: -7px;
}

.all-span-btn-com {
  position: absolute;
  left: 41%;
  bottom: -20px;
}

/*--------------------------------------
              9.team area
  -------------------------------------*/

#home-team {
  padding: 100px 0;
}

.team-box {
  position: relative;
  overflow: hidden;
}

.team-box:after,
.team-box:before {
  position: absolute;
  content: "";
}

.team-box:before {
  width: 80%;
  height: 220%;
  background: var(--main-theme-color-two);
  top: -50%;
  left: -100%;
  z-index: 1;
  transform: rotate(25deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
}

.team-box:hover:before {
  left: 10%;
}

.team-box:after {
  width: 55%;
  height: 175%;
  background-color: var(--main-theme-color-one);
  bottom: -1000%;
  left: 53%;
  transform: rotate(-33deg);
  transform-origin: center bottom 0;
  transition: all 0.8s ease 0s;
}

.team-box .box-content,
.team-box .icon {
  width: 100%;
  padding: 0 20px;
  position: absolute;
  left: 0;
  z-index: 2;
  transition: all 1.1s ease 0s;
}

.team-box:hover:after {
  bottom: -70%;
}

.team-box img {
  width: 100%;
  height: auto;
}

.team-box .box-content {
  top: -100%;
  color: #fff;
}

.team-box:hover .box-content {
  top: 30px;
}

.team-box .title {
  font-size: 24px;
  margin: 0;
  font-weight: 700;
  text-transform: capitalize;
}

.team-box .icon li a {
  display: inline-block;
  font-size: 14px;
}

.team-box .posation {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
}

.team-box .btn-4 {
  margin-top: 10px;
}

.team-box .icon {
  list-style: none;
  margin: 0;
  bottom: -100%;
}

.team-box:hover .icon {
  bottom: 25px;
}

.team-box .icon li {
  display: inline-block;
}

.team-box .icon li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #fff;
  border-radius: 50%;
  margin: 0 3px;
  color: var(--main-theme-color-one);
  text-align: center;
  transition: all 0.5s ease 0s;
}

.team-box .icon li a:hover {
  background: var(--main-theme-color-one);
  color: #fff;
}

.team-box .icon li:hover a i {
  color: #fff;
}

@media only screen and (max-width: 990px) {
  .team-box {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .team-box .title {
    font-size: 20px;
  }
}

/*--------------------------------------
              10.Testmoinal area
  -------------------------------------*/

#home-testimonial-top {
  background-image: url(/assets/img/common-img/testmoinal-bg.png);
  background-size: cover;
  padding: 120px 0 390px 0;
  background-repeat: no-repeat;
}

.color-1 {
  color: #a6d7ff;
}

.color-2 {
  color: #fff;
}

#home-testimonial {
  padding-bottom: 100px;
}

.vt-img {
  position: relative;
  margin-top: -390px;
}

.vt-img img {
  width: 100%;
  box-shadow: 0px 0px 14px -4px #000;
}

.video-play-test {
  position: absolute;
  top: -218px;
  left: 46%;
}

.video-play-test i {
  color: var(--main-theme-color-two);
  font-size: 28px;
  height: 90px;
  width: 90px;
  background: #fff;
  line-height: 90px;
  border-radius: 72%;
}

.test-slider-home-1 {
  margin-top: 120px;
}

.test-heading h2 {
  margin-top: 15px;
  margin-right: 170px;
}

.test-heading h2 span {
  color: var(--main-theme-color-two);
}

.test-flex p {
  margin-top: 30px;
}

.test-flex h6 {
  font-weight: 600;
  margin-top: 25px;
}

.start-icon i {
  color: var(--main-theme-color-two);
  margin-top: 15px;
}

.test-moinal-ing-left img {
  position: relative;
  position: relative;
  border-radius: 185px;
  height: 342px;
}

.gallerry-buttopn {
  text-align: center;
  width: 100%;
}

.qyaty {
  position: relative;
}

#home-testimonial .owl-carousel .owl-dots.disabled {
  display: block;
}

#home-testimonial .owl-theme .owl-dots .owl-dot.active span {
  background: var(--main-theme-color-one);
}

#home-testimonial .owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 5px 7px;
  background: var(--main-theme-color-two);
}

#home-testimonial .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color-one);
}

#home-testimonial .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 40px;
  position: absolute;
  right: -15px;
  top: 123px;
  transform: rotate(90deg);
}

/*--------------------------------------
                  11.contact area
  -------------------------------------*/

.map-inner iframe {
  width: 100%;
  height: 599px;
  border: 0;
  opacity: 0.5;
  position: absolute;
}

.contact-cover {
  background: linear-gradient(
    284deg,
    rgba(43, 44, 117, 0) 17%,
    rgba(245, 125, 45, 0.88) 2%
  );
  padding: 40px 154px 40px 40px;
}

.contact-heading h2 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.1px;
}

.contact-heading p {
  color: #fff;
  margin-top: 15px;
}

.phone-info p {
  color: #fff;
}

.info-office {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.phone-deta {
  display: flex;
}

.email-deta {
  display: flex;
}

.sams p {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.phone-info img {
  margin-top: 15px;
}

.phone-info img {
  margin-top: 15px;
}

.sams {
  margin-left: 15px;
}

.contact-form .form-control {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 16px;
  color: #fff;
  height: 45px;
  padding: 10px 22px;
  font-family: "Poppins", sans-serif;
}

.contact-form .nessage-text .form-control {
  height: auto;
}

.contact-form ::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.contact-form :-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.contact-form ::placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.contact-form {
  margin-top: 25px;
}

.contact-sub-btn {
  margin-top: 30px;
}

/*--------------------------------------
              07.footer
  -------------------------------------*/

.footer-para p {
  color: #fff;
  margin-top: 20px;
  font-size: 16px;
}

#footer-all-page {
  background: var(--main-theme-color-one);
  padding: 60px 0;
}

.form-control:focus {
  box-shadow: none;
}

#footer-all-page .form-control {
  border-radius: 0;
  height: 49px;
  background: transparent;
  color: #fff;
  position: relative;
}

button:focus {
  outline: none;
  box-shadow: none !important;
}

.plean-footer {
  color: #fff;
  background: var(--main-theme-color-two);
  padding: 1px 11px;
  border-radius: 0;
  font-size: 30px;
  position: absolute;
  transition: 1.4s;
  right: 12px;
}

.plean-footer:hover {
  color: var(--main-theme-color-one);
}

#footer-all-page ::placeholder {
  color: #fff;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

#footer-all-page :-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

#footer-all-page ::-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

.footer-form {
  margin-top: 30px;
}

.comm-foot-hed h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  position: relative;
}

.comm-foot-hed h6:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 38px;
  background: var(--main-theme-color-two);
  bottom: -11px;
}

.foot-list {
  margin-top: 50px;
}

.foot-list ul li {
  margin-bottom: 16px;
}

#footer-all-page ul li a {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
}

#footer-all-page ul li a:hover {
  color: var(--main-theme-color-two);
}

.quick-link {
  margin-left: 25px;
}

.foot-list-2 ul li {
  margin-bottom: 16px;
}

.quick-link-2 {
  margin-top: 70px;
  margin-left: 30px;
}

.pos-rece-1 {
  display: flex;
  margin-bottom: 25px;
}

.podt-text-1 p a {
  color: #fff;
  font-size: 16px;
}

.podt-text-1 p a:hover {
  color: var(--main-theme-color-two);
}

.podt-text-1 p {
  line-height: 24px;
  margin-bottom: 10px;
}

.podt-text-1 span {
  font-size: 16px;
  color: var(--main-theme-color-two);
  font-family: "Lato", sans-serif;
}

.post-rect-img img {
  margin-top: 5px;
  margin-right: 20px;
  transition: 1s;
  opacity: 0.5;
  width: 105px;
  height: 78px;
}

.post-rect-img img:hover {
  opacity: 1;
}

.post-cover-foot {
  margin-top: 50px;
}

.copy-right-para p {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}

.footre-bottom {
  background: #013864;
  padding: 20px 0;
}

.face {
  padding: 7px 7px;
  border: 2px solid #fff;
  border-radius: 112px;
  color: #fff;
  font-size: 18px;
  margin-right: 15px;
}

.face:hover {
  border: 2px solid var(--main-theme-color-two);
  color: var(--main-theme-color-two);
}

.no-ag {
  padding: 7px 12px !important;
}

.copy-right-icon {
  float: right;
}

/*---------------------------------
      08.About page
  ----------------------------------*/

#about-banner {
  background-image: url(/assets/img/about/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  background-position: bottom;
}

/*---------------------------------
       1.who-area
  ----------------------------------*/

#who-area {
  padding: 100px 0;
}

.left-op {
  margin-right: 120px;
}

.who-headinf-inner h2 {
  margin-bottom: 10px;
}

.who-headinf-inner p {
  padding-top: 15px;
}

.sec-who {
  margin-top: 60px;
}

.who-box {
  border: 1px solid #000;
  padding: 30px 30px 30px 30px;
}

.who-box h6 {
  font-weight: 700;
}

.who-box p {
  margin-top: 20px;
}

/*---------------------------------
      2.best-service
  ----------------------------------*/

#best-service {
  background-image: url(/assets/img/about/trd.png);
  background-size: cover;
  padding: 100px 0;
}

.best-service-heading h2 {
  color: #fff;
  padding: 0 30%;
}

.best-service-heading p {
  color: #fff;
  padding: 0px 26%;
  color: #fff;
  padding: 0px 26%;
  margin-top: 10px;
}

.best-service-heading {
  text-align: center;
}

.des-ter h6 {
  font-size: 18px;
  color: #fff;
  margin-top: 28px;
}

.des-ter p {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  margin-top: 12px;
}

.all-item-ter {
  display: flex;
  margin-top: 60px;
  justify-content: center;
}

.ter-item-one {
  text-align: center;
  width: 23%;
}

.gift-img img {
  border: 2px solid var(--main-theme-color-two);
  padding: 10px;
  border-radius: 72px;
  transition: 1.2s;
}

.all-ineer-der-ter img {
  padding-top: 30px;
}

.gift-img img:hover {
  background: var(--main-theme-color-one);
  border: 2px solid #fff;
  transform: translateY(-5px);
}

.shape-2 {
  -webkit-animation: left2right 3s linear 0s infinite;
  animation: left2right 3s linear 0s infinite;
}

@-webkit-keyframes left2right {
  50% {
    -ms-transform: translateY(5px);
    /* IE 9 */
    -webkit-transform: translateY(5px);
    /* Chrome, Safari, Opera */
    transform: translateY(5px);
  }
}

.logo-slilder {
  margin-top: 100px;
}

.all-logo img {
  width: 60% !important;
  margin: 0 30px;
  opacity: 0.3;
}

.all-logo img:hover {
  opacity: 1;
}

/*---------------------------------
      3.Nuturel img area
  ----------------------------------*/

#img-nature {
  position: relative;
}

#img-nature .container-fluid {
  padding: 0;
}

.nuture-img-item {
  position: relative;
  transition: 1.2s;
}

.overay-nutre {
  position: absolute;
  top: 0;
  background: #0140739e;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 1.3s;
}

.overay-nutre:hover {
  opacity: 1;
  cursor: pointer;
}

.overay-nutre a i {
  font-size: 55px;
  color: #fff;
  position: relative;
  left: 42%;
  top: 35%;
}

/*---------------------------------
       9.Tour packages page
  ----------------------------------*/

#tour-packes {
  background-image: url(/assets/img/tour/packges-bnner.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 210px 0;
  background-position: bottom;
}

.travel-pac {
  padding: 100px 0 0 0 !important;
  background: none !important;
}

.des-cov-1:hover .des-img-1 img {
  transform: scale(1.1);
  opacity: 0.9;
}

.des-img-1 {
  overflow: hidden;
}

.des-img-1 img {
  width: 100%;
  transition: 2s;
}

.des-cov-1 {
  margin-top: 35px;
}

.can-if {
  margin-bottom: 15px !important;
}

/*-----------------------------------------
              10.tour-packes-deatils page
  ----------------------------------------*/

// #tour-packes-deatils {
//   background-image: url(/assets/img/tour/packges-bnner.png);
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 150px 0 150px 0;
//   background-position: bottom;
// }
// .bookoing-secrty,
// .all-details-tour{
//   margin-top: 30px;
// }
/*-------------------------------- 
      1.tour-details main content
  -----------------------------------*/

#tour-detailes-main {
  padding: 60px 0;
}

.tour-heading-detailse h2 span {
  color: var(--main-theme-color-two);
}

.tour-heading-detailse h2 {
  margin: 10px 0;
}

.tour-heading-detailse h6 {
}

.start-icon-deta a i {
  color: var(--main-theme-color-two);
  font-size: 18px;
}

.revews h6 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  margin-left: 37px;
}

.start-text-details {
  display: flex;
}

.start-icon-deta {
  margin-top: 15px;
}

.tour-main-informa {
  display: flex;
  justify-content: space-around;
  border: 2px solid var(--main-theme-color-two);
  padding: 18px 0;
  margin-top: 30px;
}

.tour-main-informa h6 span {
  margin-left: 24px;
}

.tour-main-informa h6 {
  font-size: 18px;
  color: var(--paragraph-color);
}

.det-asor-img img {
  // margin-top: 30px;
  width: 100%;
}

.rweal-reat {
  margin-top: 25px;
}

.rweal-reat p {
  margin-top: 20px;
}

//   .packages-includ {
//     margin-top: 50px;
//   }

.right-includ {
}

.right-includ ul li {
  font-size: 16px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  color: var(--paragraph-color);
  font-weight: 400;
  letter-spacing: 0.25px;
}

.right-includ ul li i {
  color: var(--main-theme-color-two);
  margin-right: 10px;
}

.left-includ ul li i {
  color: red;
  margin-right: 10px;
}

.all-ul-includ {
  display: flex;
  justify-content: space-between;
}

.map-inclid {
  margin-top: 50px;
}

.map-inclid iframe {
  width: 100%;
  margin-top: 30px;
  border: 3px solid var(--main-theme-color-two);
}

.client-revews {
  margin-top: 50px;
}

.clients-desnigation h6 {
  font-size: 18px;
  color: var(--paragraph-color);
  margin-top: 10px;
  font-weight: 600;
}

.cliennt-img img {
  width: 152px;
  height: 155px;
}

.clients-desnigation p {
  margin-top: 10px;
}

.client-info-rev {
  display: flex;
  margin-top: 30px;
}

.clients-desnigation {
  // margin-left: 40px;
  max-width: 100%;
}

.clients-para {
  margin-top: 30px;
}

.clients-para h6 {
  font-size: 18px;
  font-weight: 600;
}

.clients-para p {
  margin-top: 18px;
}

.start-one-ras {
  display: flex;
  justify-content: space-between;
}

.client-start-comment {
  background: var(--main-theme-color-two);
  padding: 50px;
  border-radius: 20px;
  margin-top: 56px;
}

.start-one-ras h6 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
}

.start-one-ras i {
}

.all-women-heading h3 {
  font-weight: 600;
  color: #fff;
  letter-spacing: 1.1px;
  margin-bottom: 40px;
}

.revs-form .form-control {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 16px;
  color: #fff;
  height: 45px;
  padding: 10px 22px;
  font-family: "Poppins", sans-serif;
}

.revs-form .nessage-text .form-control {
  height: auto;
}

.revs-form ::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.revs-form ::-moz-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.revs-form :-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.revs-form ::-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.revs-form ::placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.revs-form {
  margin-top: 35px;
}

.all-pacj-dfgh {
}

.all-pacj-dfgh h6 {
  font-size: 24px;
  background: var(--main-theme-color-one);
  padding: 20px 31px 20px 30px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

.booking-info #datepicker-5 {
  height: 45px;
  width: 100%;
}

#datepicker-5 .form-control {
  padding: 0 50px;
  height: 45px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
}

#datepicker-5 > span {
  position: absolute;
  left: 18px;
  z-index: 9;
  color: #ffffff;
  top: 11px;
  font-size: 20px;
}

.booking-info #datepicker-6 {
  height: 45px;
  width: 100%;
}

#datepicker-6 .form-control {
  padding: 0 50px;
  height: 45px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
}

#datepicker-6 > span {
  position: absolute;
  left: 18px;
  z-index: 9;
  color: #ffffff;
  top: 11px;
  font-size: 20px;
}

.bookk0-natd-form {
  border: 1px solid var(--main-theme-color-two);
  padding: 24px 25px 55px 25px;
}

.bookk0-natd-form ::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.bookk0-natd-form :-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.bookk0-natd-form::placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.booking-alrtu label {
  margin: 0;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}

.booking-alrtu select {
  background: var(--main-theme-color-two);
  padding: 6px 0px 6px 17px;
  height: 45px;
  width: 100%;
  color: #ffffff;
  font-size: 17px;
  border: 2px solid;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 0;
  position: relative;
  cursor: pointer;
}

.booking-alrtu select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.serv-ivmf {
  position: absolute;
  right: 20px;
  top: 74px;
  color: #fff;
}

.booking-alrtu {
  position: relative;
}

.widet {
  width: 100%;
}

.sunb-btn-naple {
  margin-top: 30px;
}

/*-------------------------------
          11.Model style
  --------------------------------*/

.model-details h5 {
  font-size: 28px;
  text-align: center;
}

.mdel-form label {
  margin: 0;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: var(--main-theme-color-one);
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  letter-spacing: 0.25px;
}

.mdel-form .form-control {
  background: transparent;
  border: 1px solid var(--main-theme-color-one);
  border-radius: 0;
  font-size: 18px;
  color: #131313;
  height: 45px;
  padding: 10px 22px;
  font-family: "Lato", sans-serif;
  font-weight: 100;
}

.mainm-sel {
  position: relative;
}

.mainm-sel select {
  background: #ffffff;
  padding: 6px 0px 6px 17px;
  height: 45px;
  width: 100%;
  color: #131313;
  font-size: 18px;
  border: 2px solid;
  font-family: "Lato", sans-serif;
  font-weight: 100;
  border-radius: 0;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--main-theme-color-one);
}

.mainm-sel select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.serv-ivmf-2 {
  position: absolute;
  right: 20px;
  top: 66px;
  color: #000;
}

.mdel-form ::-webkit-input-placeholder {
  color: #131313;
  font-size: 18px;
}

.mdel-form :-ms-input-placeholder {
  color: #131313;
  font-size: 18px;
}

.mdel-form ::placeholder {
  color: #131313;
  font-size: 18px;
  font-weight: 100;
}

.check-box-many label {
  font-size: 12px;
  margin-left: 15px;
  margin-right: 24px;
}

.check-box-many {
  display: flex;
  position: relative;
  left: 48px;
}

.custom-control {
  padding: 0;
}

.travel-tyepe {
  display: flex;
}

.modal-dialog {
  max-width: 585px;
  margin: 1.75rem auto;
}

.widet-2 {
  width: 100%;
  font-size: 18px;
}

.sunb-btn-mod {
  margin-top: 10px;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-body {
  background: #fff;
  box-shadow: 0px 0px 6px -1px;
  padding: 50px 50px;
}

button.close {
  background: var(--main-theme-color-two);
  padding: 3px 10px;
  border-radius: 50px;
  color: #fff;
  position: absolute;
  right: -13px;
  top: -13px;
  font-size: 30px;
  opacity: 1;
  transition: 1.3s;
}

.close:not(:disabled):not(.disabled):hover {
  color: #ffffff;
  text-decoration: none;
  opacity: 1;
  background: var(--main-theme-color-one);
}

/*----------------------------------
          12.service page
  -----------------------------------*/

#service-main-banner {
  background-image: url(/assets/img/service/service.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  background-position: bottom;
}

#wer-service {
  padding: 100px 0;
}

.service-ads-heading h2 span {
  color: var(--main-theme-color-two);
}

.service-ads-heading h2 {
  margin-right: 50px;
}

.services-pata {
}

.services-pata p {
  margin-top: 20px;
}

.weject-on {
  box-shadow: 0px 0px 11px -6px #01467c;
  padding: 30px 15px 30px 15px;
  transition: 1.2s;
}

.weject-on h6 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.weject-on p {
  margin-top: 10px;
}

.weject-on:hover {
  transform: translateY(10px);
}

.ers {
  margin-top: 30px;
}

.ers-2 {
  margin-top: 50px;
}

.ser-ab-deta {
  padding-top: 100px;
}

/*----------------------------------
          13.Gallery page
  -----------------------------------*/

#gallery-banner {
  background-image: url(/assets/img/gallery/gal.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  background-position: bottom;
}

#gallery-area {
  padding: 65px 0 100px 0;
}

.gallery-main-hover img {
  display: block;
  width: 100%;
  height: auto;
}

.gallery-main-hover {
  position: relative;
}

.gallery-main-hover img {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 35px;
}

.gall-overlay {
  background: linear-gradient(
    189deg,
    #90974000 64%,
    rgba(245, 125, 45, 0.88) 29%
  );
  width: 100%;
  height: 0%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  transition: 0.5s ease;
}

.gallery-main-hover:hover .gall-overlay {
  height: 100%;
}

.icon-tsdg h6 {
  color: #fff;
  margin-left: 18px;
}

/*------------------------------
          14.faq page
  --------------------------------*/

#faq-banner {
  background-image: url("/assets/img/common-img/faq.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  background-position: bottom;
}

.mb-0 > a {
  display: block;
  position: relative;
  color: #fff;
}

.mb-0 > a:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 800;
  position: absolute;
  right: 0;
  background: white;
  border-radius: 50px;
  color: var(--main-theme-color-two);
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.mb-0 > a[aria-expanded="true"]:after {
  content: "\f077";
  /* fa-chevron-up */
}

.acrond-heading h6 {
  font-size: 24px;
  font-weight: 600;
}

.card-header {
  background: var(--main-theme-color-two);
}

.card {
  margin-bottom: 30px;
}

.card-header h5 {
  font-size: 18px;
  font-weight: 400;
}

.card-body {
  background: var(--main-theme-color-one);
  color: #fff;
}

.card-body p {
  color: #fff;
  font-size: 15px;
  line-height: 28px;
}

.acrond-heading h6 {
  margin-bottom: 30px;
}

.mar-faq {
  margin-top: 60px;
}

.all-catagory {
  border: 1px solid var(--main-theme-color-two);
}

.all-catagory h6 {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--main-theme-color-two);
  padding: 15px 0px 14px 21px;
  color: var(--main-theme-color-two);
}

.all-catagory h5 a {
  color: var(--main-theme-color-two);
}

.all-catagory h5 {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--main-theme-color-two);
  padding: 15px 0px 14px 21px;
}

.all-catagory h5:nth-child(8) {
  border-bottom: none;
}

.all-catagory h5:hover {
  background: var(--main-theme-color-one);
  cursor: pointer;
}

.all-catagory h5:hover a {
  color: #fff;
}

.all-catagory h5:nth-child(2) {
  background: var(--main-theme-color-one);
}

.faq-active {
  color: #fff !important;
}

/*----------------------------------
          15.contact page
  -----------------------------------*/

#contact-banner {
  background-image: url("/assets/img/common-img/contact.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  background-position: bottom;
}

.conta-main-map iframe {
  width: 100%;
  height: 540px;
}

.head-main-con h2 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.1px;
}

.all-main-con {
  background: #ffa748;
}

.all-dfkj {
  background: var(--main-theme-color-two);
  padding: 50px 35px;
  position: relative;
  margin-top: -220px;
}

.adserg h6 {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
}

.adress-cover-main {
  padding: 0 85px;
}

.adserg p {
  color: #fff;
  font-size: 16px;
  margin-top: 15px;
}

.adserg p a {
  color: #fff;
}

.adserg p a:hover {
  color: var(--main-theme-color-one);
}

.jhjgfd {
  margin-top: 0 !important;
}

.adserg ul li a {
  color: #fff;
  font-size: 20px;
  margin-right: 20px;
}

.adserg ul li a:hover {
  color: var(--main-theme-color-one);
}

.adserg ul li {
  display: inline-block;
  margin-top: 15px;
}

.con-ader {
  margin-top: 30px;
}

.contact-3-cover {
  margin-right: 80px;
}

.all-dfkj:after {
  content: "";
  background: #fff;
  position: absolute;
  height: 102%;
  width: 7px;
  top: -6px;
  left: 58%;
  transform: rotate(11deg);
}

#contact-main-area {
  padding-bottom: 100px;
}

.sending-gif img {
  width: 10%;
  position: absolute;
  bottom: -5px;
  left: 118px;
}

/*-----------------------------------
              16.blog classic page
  -----------------------------------*/

#blog-banner {
  background-image: url(/assets/img/common-img/blog.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  background-position: bottom;
}

#blog-content {
  padding: 100px 0;
}

.blog-items {
  transition: 1.2s;
}

.blog-items:hover {
  transform: translateY(5px);
}

.blog-item-img img {
  transition: 1.6s;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
}

.blog-item-img img:hover {
  transform: scale(1.2);
}

.blog-item-img {
  overflow: hidden;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.blog-item-det h6 {
  border-bottom: 1px solid #a6a6a6;
  padding-bottom: 15px;
}

.blog-item-det h6 a {
  font-size: 19px;
  font-weight: 600;
  color: var(--main-theme-color-one);
  line-height: 28px;
}

.blog-items:hover .blog-item-det h6 a {
  color: var(--main-theme-color-two);
}

.blog-athou-img {
  display: inline-flex;
}

.blog-flex-same {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.blog-athou-img a img {
  width: 48px;
  height: 48px;
  border-radius: 45px;
}

.blog-athou-img p a {
  color: #a6a6a6;
}

.blog-athou-img p a:hover {
  color: var(--main-theme-color-one);
}

.blog-times {
  display: flex;
}

.blog-times i {
  color: #a6a6a6;
  margin-top: 20px;
}

.blog-times p a {
  color: #a6a6a6;
}

.blog-times p a:hover {
  color: var(--main-theme-color-one);
}

.icon-blog-item {
  margin-top: 15px;
  justify-content: flex-end;
  display: flex;
}

.icon-blog-item a i {
  font-size: 16px;
  color: #a6a6a6;
  margin-left: 10px;
}

.icon-blog-item a i:hover {
  color: var(--main-theme-color-one);
}

.blog-athou-img p {
  font-size: 16px;
  color: #a6a6a6;
  font-weight: 500;
  margin-top: 11px;
  margin-left: 10px;
}

.blog-times p {
  font-size: 16px;
  color: #a6a6a6;
  font-weight: 500;
  margin-top: 11px;
  margin-left: 10px;
}

.blog-item-det {
}

.dtes-not {
  padding: 20px 25px 20px 20px;
  border-bottom-right-radius: 23px;
  border-bottom-left-radius: 23px;
  box-shadow: 0px 0px 9px -5px;
}

.clas-mar {
  margin-top: 30px;
}

/*--------------------------
      17.pagination
  ----------------------------*/

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: transparent;
  cursor: not-allowed;
  background-color: transparent;
  border-color: transparent;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 16px;
  font-weight: 700;
  padding: 6px 12px;
  margin-left: 15px;
  background: none;
  color: #4f535b;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background: var(--main-theme-color-two);
  border-color: #3cb1f1;
  border-radius: 50%;
}

.blog-pagination {
  margin: 70px auto 0 auto;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #4abbe5;
  color: #fff !important;
  margin: 5px 0;
}

/*-----------------------------------
          18.blog-single page
  -----------------------------------*/

#blog-single-main {
  padding: 100px 0;
}

.al-img-at1 {
  display: flex;
}

.al-img-at1 p {
  color: #a6a6a6;
  font-size: 16px;
  font-weight: 700;
  margin-top: 11px;
  margin-left: 15px;
}

.al-img-at1 p a {
  color: #a6a6a6;
}

.al-img-at1 p a:hover {
  color: var(--main-theme-color-one);
}

.al-img-at2 {
  display: flex;
}

.al-img-at2 a i {
  font-size: 16px;
  margin-top: 17px;
  color: var(--main-theme-color-one);
  margin-left: 60px;
}

.al-img-at2 p {
  color: #a6a6a6;
  font-size: 16px;
  font-weight: 700;
  margin-left: 15px;
  margin-top: 10px;
}

.al-img-at2 p a {
  color: #a6a6a6;
}

.al-img-at2 p a:hover {
  color: var(--main-theme-color-one);
}

.authour-single {
  display: flex;
  margin-top: 30px;
}

.blog-single-dd-hed {
  margin-top: 25px;
}

.blog-single-dd-hed h4 a {
  font-weight: bold;
  color: var(--main-theme-color-one);
}

.blog-single-dd-hed h4 a:hover {
  color: var(--main-theme-color-two);
}

.blog-single-dd-hed p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
}

.blog-sighn-img img {
  width: 100%;
  transform: scale(1.1);
  transition: 2s;
}

.blog-sighn-img img:hover {
  transform: scale(1);
}

.dubbel-img {
  margin-top: 30px;
  overflow: hidden;
}

.blog-sighn-img {
  overflow: hidden;
}

.dubbel-img img {
  width: 100%;
  transform: scale(1.1);
  transition: 2s;
}

.dubbel-img img:hover {
  transform: scale(1);
}

.sec-par-tree {
  margin-top: 23px;
}

.sec-par-tree p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
}

.box-tes-bl blockquote {
  font-size: 16px;
  line-height: 26px;
}

.box-tes-bl {
  padding: 50px 86px 35px 126px;
  box-shadow: 0px 2px 22px -17px;
  position: relative;
  margin-top: 30px;
}

.box-tes-bl i {
  padding: 12px;
  border-radius: 4px;
  position: absolute;
  left: 27px;
  top: 43px;
  font-size: 50px;
  color: var(--main-theme-color-one);
  transform: rotateY(180deg);
}

.blog-clss-img a img {
  width: 100px;
  height: 75px;
}

.client-chart-img img {
  border-radius: 300px;
  height: 100px;
  width: 100px;
}

.al-img-at1 a img {
  width: 48px;
  height: 48px;
  border-radius: 32px;
}

.coomm-2client-img img {
  border-radius: 300px;
  height: 100px;
  width: 100px;
}

.inner-tahes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  padding: 35px 0;
}

.inner-tahes ul {
  display: flex;
}

.all-single-cover {
  margin-right: 30px;
}

.inner-tahes ul li {
  font-size: 16px;
  margin-right: 7px;
}

.inner-tahes ul li a {
  color: #a6a6a6;
}

.inner-tahes ul li a:hover {
  color: var(--main-theme-color-one);
}

.sal-client-chart {
  display: flex;
  margin-top: 35px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 25px;
}

.det-client-chart p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.det-client-chart a i {
  font-size: 16px;
  margin-right: 45px;
}

.det-client-chart a i:hover {
  color: var(--main-theme-color-two);
}

.det-client-chart {
  margin-left: 30px;
}

.all-clien-sayt {
  margin-top: 40px;
}

.all-clien-sayt h2 {
  color: #a6a6a6;
  margin-bottom: 30px;
}

.alow-rasta p {
  font-size: 16px;
  font-weight: 600;
}

.coomm-2cliebt-hed .dadate-titm {
  font-size: 12px;
  margin-left: 30px;
}

.alow-rasta {
  display: flex;
  justify-content: space-between;
}

.all-kal {
  display: flex;
}

.all-text-emar p {
  font-size: 16px;
  margin-top: 10px;
}

.coom-2-client {
  display: flex;
  margin-left: 70px;
  margin-bottom: 50px;
}

.coomm-2cliebt-hed {
  margin-left: 30px;
}

.mar-nened {
  margin-left: 150px;
}

.darkan-mara p a:hover {
  color: var(--main-theme-color-two);
}

.lave-cooment h4 {
  font-weight: 600;
}

.leav-form label {
  font-size: 16px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  margin: 0;
  color: #a6a6a6;
  margin-bottom: 10px;
  margin-top: 20px;
}

.leav-form span {
  color: red;
}

.leav-form .form-control {
  border: 1px solid #a6a6a6;
  background: #ffffff;
  border-radius: 0px;
  height: 50px;
}

.email-fred {
  margin: 0 10px;
}

.lave-tree-form {
  display: flex;
}

.text-are textarea {
  height: auto !important;
}

.form-for-btn {
  margin-top: 60px;
}

.leav-form {
  margin-top: 35px;
}

.lave-cooment {
  margin-top: 100px;
}

.alo-search .form-control {
  border-radius: 0;
  height: 49px;
  background: #f3f3f3;
  position: relative;
  border: none;
}

.alo-search .form-control:focus {
  border: none;
}

.search-icon-blog {
  color: #fff;
  background: var(--main-theme-color-two);
  padding: 8px 14px;
  border-radius: 0;
  font-size: 20px;
  position: absolute;
  transition: 1.4s;
  right: 12px;
  box-shadow: 0px 0px 8px -3px #000;
}

.search-icon-blog:hover {
  box-shadow: none;
  color: #000;
}

.coomm-seclitor {
  position: relative;
  margin-top: 60px;
}

.blog-hki-hed h5 {
  line-height: 0;
  letter-spacing: 0.25px;
  margin-bottom: 55px;
}

.blog-hki-hed h5:after {
  content: "";
  height: 1px;
  width: 42px;
  background: var(--main-theme-color-one);
  position: absolute;
  left: 0;
  top: 25px;
}

.item-cata {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.item-cata {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.icon-catr a {
  font-size: 16px;
  color: #a6a6a6;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.icon-catr a i {
  margin-right: 10px;
  color: #a6a6a6;
}

.co-num a {
  font-size: 16px;
  color: #a6a6a6;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.item-cata:hover .icon-catr a {
  color: var(--main-theme-color-one);
}

.item-cata:hover .icon-catr a i {
  color: var(--main-theme-color-one);
}

.item-cata:hover .co-num a {
  color: var(--main-theme-color-one);
}

.alo-blog-clss-text h6 a {
  font-size: 15px;
  line-height: 1.2;
  color: #000;
}

.alo-blog-clss-text h6 a:hover {
  color: var(--main-theme-color-one);
}

.alo-blog-clss-text p {
  margin-top: 6px;
  font-size: 14px;
}

.blog-cliccs {
  display: flex;
  margin-top: 30px;
}

.alo-blog-clss-text {
  margin-left: 12px;
}

.catago-item {
  display: flex;
}

.catago-item a {
  font-family: "Lato", sans-serif;
  color: rgb(148, 154, 167);
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 15px;
  background: rgb(237, 238, 239);
  transition: all 0.3s ease 0s;
  padding: 7px 13px;
  border-radius: 23px;
  margin-right: 4px;
}

.catago-item a:hover {
  background: rgb(255, 157, 51);
  color: #fff;
}

.inteagram-img a img {
  margin: 3px 1px;
  width: 58px;
  height: 58px;
}

/*-----------------------------------
          19.404 page
  -----------------------------------*/

.for-img {
  text-align: center;
}

#for-page {
  padding: 160px 0;
}

.for-text-btn h4 {
  color: var(--main-theme-color-two);
  font-weight: 500;
  text-align: center;
}

.for-text-btn {
  margin-top: 100px;
}

.for-btn {
  margin-top: 40px;
  text-align: center;
}

.valedoter {
  display: none;
}

.heading {
  display: none;
}

/*----------------------------
     20. preloder
  --------------------------------*/

#preloader {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

#status {
  /* loading.io */
  background-image: url(/assets/img/loader.gif);
  background-repeat: no-repeat;
  width: 125px;
  height: 121px;
  position: absolute;
  left: 48%;
  top: 45%;
  margin-top: -33px;
  margin-left: -33px;
  background-position: center;
}
.slick-slider-home {
  padding: 100px 0 220px 0;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
#banner-home-slider .owl-item.active .slick-slider-home .slider-owl .title {
  -webkit-animation: 2s 0.9s fadeIn both;
  animation: 2s 0.9s fadeIn both;
}

#banner-home-slider
  .owl-item.active
  .slick-slider-home
  .slider-owl
  .slider-sttle {
  -webkit-animation: 1s 0.2s bounceInDown both;
  animation: 1s 0.2s bounceInDown both;
}

#banner-home-slider
  .owl-item.active
  .slick-slider-home
  .slider-owl
  .slider-pararp {
  -webkit-animation: 1s 2s lightSpeedIn both;
  animation: 1s 2s lightSpeedIn both;
}

#banner-home-slider .owl-item.active .slick-slider-home .slider-owl .btn-1 {
  -webkit-animation: 1s 2.2s flipInX both;
  animation: 1s 2.2s flipInX both;
}

#banner-home-slider {
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.banner-text-home h1 {
  margin-top: 25px;
}

.banner-text-home h6 {
  color: var(--main-theme-color-two);
}

.banner-text-home p {
  color: #fff;
  margin-top: 15px;
}

.button-common {
  margin-top: 45px;
}

.slider-owl {
  width: 57%;
  text-align: center;
  margin: 0 auto;
}

#banner-home-slider .owl-dots {
  display: none;
}

#banner-home-slider .owl-nav,
#slider-des .owl-nav {
  position: absolute;
  width: 100%;
  top: 44%;
}

#banner-home-slider .owl-prev,
#slider-des .owl-prev {
  position: absolute;
  left: 0;
  background: var(--main-theme-color-two);
  color: #fff;
  padding: 13px 16px !important;
  border-radius: 62px;
  font-size: 30px;
}

#banner-home-slider .owl-next,
#slider-des .owl-next {
  position: absolute;
  right: 0;
  background: var(--main-theme-color-two);
  color: #fff;
  padding: 13px 16px !important;
  border-radius: 62px;
  font-size: 30px;
}

#banner-home-slider .owl-next:hover,
#banner-home-slider .owl-prev:hover,
#slider-des .owl-next:hover,
#slider-des .owl-prev:hover {
  // background-image: url(/assets/img/slider/slider-1.jpg);
  // background-size: contain;
  opacity: 0.8;
}
#slider-des .owl-prev {
  left: -20px;
  line-height: 18px;
}
#slider-des .owl-next {
  right: -20px;
  line-height: 18px;
}
.slider-des {
  z-index: inherit !important;
}
/*-----------css change--------*/

.total-price h3 {
  line-height: 46px;
}

.check-box-many {
  display: flex;
  position: relative;
  left: 15px;
}

.bookk0-natd-form label {
  margin: 0;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  letter-spacing: 0.25px;
}

.bookk0-natd-form .form-control {
  padding: 0 20px;
  height: 45px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
}

.bookk0-natd-form select {
  background: var(--main-theme-color-two);
  padding: 6px 0px 6px 17px;
  height: 45px;
  width: 100%;
  color: #ffffff;
  font-size: 17px;
  border: 2px solid;
  font-weight: 500;
  border-radius: 0;
  position: relative;
  cursor: pointer;
}

.book-in i {
  color: #fff;
}

.sunb-btn-naple {
  margin-top: 10px;
}

.bookk0-natd-form input::placeholder {
  color: #ffff;
}

.bookk0-natd-form input::-moz-placeholder {
  color: #ffff;
}

.bookk0-natd-form input:-ms-input-placeholder {
  color: #ffff;
}

.bookk0-natd-form input:-moz-placeholder {
  color: #ffff;
}

.all-price {
  display: flex;
}

.price-tour {
  width: 47%;
}

.price-tour h2 {
  font-size: 50px;
  text-align: right;
  line-height: 112px;
}

.price-tour h2 span {
  color: var(--main-theme-color-two);
}

.gall-overlay {
  background: linear-gradient(
    189deg,
    rgba(245, 125, 45, 0.88) 29%,
    rgba(245, 125, 45, 0.88) 29%
  );
  width: 100%;
  height: 0%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  transition: 0.5s ease;
}

.all-cover-hall {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.icon-tsdg a i {
  font-size: 30px;
  color: #fff;
  margin-bottom: 87px;
}

.gall-heding-travel h6 {
  font-size: 26px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.icon-tsdg {
  text-align: center;
}

/*----------------------------
     Home Page 3
  --------------------------------*/
/* --banner area-- */

#banner-area-three {
  padding: 200px 0;
  background-image: url(/assets/img/home-03/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}

#banner-area-three::after {
  content: "";
  position: absolute;
  background: var(--main-theme-color-one) 69;
  height: 100%;
  width: 100%;
  top: 0;
}

#banner-area-three .main-com {
  margin: 0;
  z-index: 9;
}

#banner-area-three .nav-fill {
  width: 30%;
}

#banner-area-three .nav-tabs .nav-link.active {
  color: #fff;
  background-color: var(--main-theme-color-two);
  border-color: transparent;
  border-radius: 0;
}

#banner-area-three .nav-tabs .nav-link:focus,
#banner-area-three .nav-tabs .nav-link:hover {
  border-color: transparent;
}

#banner-area-three select {
  background: #fff;
  padding: 6px 0px 6px 50px;
  height: 45px;
  width: 100%;
  color: var(--main-theme-color-one);
  font-size: 16px;
  border: 1px solid;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 5px;
}

#banner-area-three .nav-tabs .nav-link {
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
}

/* --Our Advantages area-- */

#our-advantages-area {
  padding: 100px 0;
}

.adventage-box {
  text-align: center;
  padding-top: 20px;
}

.adventage-icon-box {
  height: 100px;
  width: 100px;
  text-align: center;
  background: #ffff;
  line-height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0px 10px 37px -23px var(--main-theme-color-one);
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.adventage-box:hover .adventage-icon-box {
  background-color: var(--main-theme-color-two);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.adventage-box:hover .flaticon-aeroplane:before,
.adventage-box:hover .flaticon-group:before,
.adventage-box:hover .flaticon-search:before,
.adventage-box:hover .flaticon-booking:before {
  color: #fff;
}

.adventage-box h6 {
  padding-top: 20px;
  font-weight: 600;
  line-height: 35px;
}

.adventage-box p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 7px;
}

.flaticon-aeroplane:before {
  content: "\f100";
  font-size: 45px;
  color: #000;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.flaticon-group:before {
  content: "\f101";
  font-size: 45px;
  color: #000;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.flaticon-search:before {
  content: "\f102";
  font-size: 45px;
  color: #000;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.flaticon-booking:before {
  content: "\f103";
  font-size: 45px;
  color: #000;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

/* --find area-- */

#find-arae {
  padding: 160px 0;
  background-image: url(/assets/img/home-03/find-arae.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#find-arae:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #01407c57;
  position: absolute;
  top: 0;
  z-index: 1;
}

.find-inner-area {
  text-align: center;
  z-index: 999;
  position: relative;
}

.find-inner-area h2 {
  color: #fff;
  font-size: 34px;
  font-weight: 400;
  padding-bottom: 15px;
}

/* testimonial */
#testimonial-area-min-slider {
  background-image: url(/assets/img/home-03/test-mo-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
  padding: 100px 0;
}

#testimonial-area-min-slider:after {
  position: absolute;
  background-color: #01407ccc;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
}

#testimonial-area-min-slider .client-thumb-outer {
  position: relative;
  max-width: 440px;
  margin: 0 auto;
  overflow: hidden;
}

#testimonial-area-min-slider .client-thumb-outer .client-thumbs-carousel {
  position: relative;
  max-width: 140px;
  margin: 0 auto;
}

#testimonial-area-min-slider
  .client-thumb-outer
  .client-thumbs-carousel
  .owl-stage-outer {
  overflow: visible;
}

#testimonial-area-min-slider
  .client-thumb-outer
  .client-thumbs-carousel
  .thumb-item {
  position: relative;
  border-radius: 50%;
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}

#testimonial-area-min-slider .client-thumb-outer .thumb-item .thumb-box {
  width: 110px;
  height: 110px;
  cursor: pointer;
  border-radius: 50%;
}

#testimonial-area-min-slider
  .client-thumb-outer
  .client-thumbs-carousel
  .thumb-item
  img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.5;
  border-radius: 50%;
}

#testimonial-area-min-slider .owl-item.active .thumb-item .thumb-box img {
  opacity: 1;
}

#testimonial-area-min-slider .owl-item.active .thumb-content {
  opacity: 1;
}

#testimonial-area-min-slider .owl-item.active .thumb-box::after {
  font-family: "Font Awesome 5 Free";
  content: "\f10e";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  color: var(--main-theme-color-two);
  font-size: 40px;
  transform: translateX(84px);
  margin-top: -40px;
}

#testimonial-area-min-slider .owl-item.active .thumb-box {
  height: 150px;
  width: 150px;
}

#testimonial-area-min-slider .owl-nav {
  display: none;
}

#testimonial-area-min-slider .text p {
  color: #fff;
}

#testimonial-area-min-slider .text h5 {
  padding-top: 30px;
  font-weight: 500;
  color: var(--main-theme-color-two);
}

#testimonial-area-min-slider .text h6 {
  color: #fff;
  font-size: 18px;
  padding-top: 10px;
}

/* --travel agent-- */

#travel-agent-area {
  padding: 100px 0;
}

.agent-img {
  text-align: center;
}

.agent-img img {
  box-shadow: 0px 0px 26px -15px #000;
  border-radius: 50%;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.agent-box:hover .agent-img img {
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.agent-details {
  text-align: center;
  margin-top: -85px;
  box-shadow: 0px 10px 31px -23px var(--main-theme-color-one);
  padding: 110px 0 40px 0;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.agent-details h5 {
  font-weight: 600;
  padding-bottom: 5px;
}

.agent-details p {
  font-weight: 500;
  font-size: 16px;
}

.agent-box:hover .agent-details {
  box-shadow: 0px 8px 28px -9px var(--main-theme-color-one);
}

.agent-box:hover .agent-icon {
  opacity: 1;
}

.agent-icon {
  margin-top: -25px;
  opacity: 0;
  transition: 0.4s all;
}

.agent-icon ul {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 8px -4px var(--main-theme-color-one);
  width: 70%;
  margin: 0 auto;
  padding: 6px 10px;
  border-radius: 45px;
  background-color: #ffff;
}

.agent-icon ul li i {
  color: #ffff;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  line-height: 25px;
  font-size: 14px;
}

.agent-icon ul li:nth-child(1) i {
  background-color: #440991;
}

.agent-icon ul li:nth-child(2) i {
  background-color: #51aaee;
}

.agent-icon ul li:nth-child(3) i {
  background-color: #2361fb;
}

.agent-icon ul li:nth-child(4) i {
  background-color: #f25d91;
}

/* --Blog-Area-- */

#blog-area-three {
  padding: 0 0 100px 0;
}

.img--slider-area-inst {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay-blog {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-theme-color-one);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.img--slider-area-inst:hover .overlay-blog {
  width: 75%;
  padding: 14px;
}

.inner-area-insta {
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 18px;
}

.inner-button-text-alon {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.inner-button-text-alon h6 {
  font-size: 16px;
  color: #fff;
}

.insta-details-area {
  padding-top: 50px;
}

.insta-details-area h6 a {
  font-size: 18px;
  font-weight: 700;
  color: #f58942;
}

.insta-details-area p {
  font-size: 16px;
  color: #fff;
  padding-top: 18px;
  line-height: 24px;
  padding-bottom: 20px;
}

.insta-details-area a {
  font-weight: 600;
  color: #fff;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.insta-details-area a:hover {
  color: #f58942;
}

.blog-box-details {
  padding: 25px 20px;
  box-shadow: 0px 0px 9px -4px #000;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.blog-box-admin {
  display: flex;
  justify-content: space-between;
}

.blog-box-admin ul li {
  display: inline-flex;
  font-size: 16px;
  color: var(--paragraph-color);
}

.blog-box-admin ul li a {
  color: var(--paragraph-color);
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.blog-box-admin ul li a:hover {
  color: var(--main-theme-color-two);
}

.blog-box-heading {
  padding-top: 15px;
}

.blog-box-heading a {
  font-weight: 500;
  line-height: 35px;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.blog-box-heading a:hover {
  color: var(--main-theme-color-two);
}

.blog-box-para {
  padding-top: 20px;
}

.blog-box-para p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 35px;
}

.blog-box-para a {
  font-size: 16px;
  color: #fda546;
}

.blog-img-area {
  overflow: hidden;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.blog-img-area a img {
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
  width: 100%;
}

.blog-box-area:hover .blog-img-area a img {
  transform: scale(1.2) skewY(3deg);
  -webkit-transform: scale(1.2) skewY(3deg);
  -moz-transform: scale(1.2) skewY(3deg);
  -ms-transform: scale(1.2) skewY(3deg);
  -o-transform: scale(1.2) skewY(3deg);
}

/*----------------------------
     Home Page 4
  --------------------------------*/

/* --banner area-- */
#banner-area-four {
  padding: 100px 0;
}

.banner-area-social-list ul li {
  margin-bottom: 20px;
}

.banner-area-social-list ul li a i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: var(--main-theme-color-one);
  border: 2px solid var(--main-theme-color-one);
  font-size: 18px;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.banner-area-social-list ul li a i:hover {
  color: var(--main-theme-color-two);
  border: 2px solid var(--main-theme-color-two);
}

.four-items {
  background-image: url(/assets/img/home-04/banner-area.png);
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.around-bg {
  text-align: center;
}

.around-bg h1 {
  padding-top: 75px;
}

.around-bg h1 a {
  font-size: 65px;
  color: #fff;
  transition: 0.22s;
}

.around-bg h1 a:hover {
  text-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
}

.around-bg h2 {
  padding-top: 20px;
}

.around-bg h2 a {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
}

.pulse {
  width: 60px;
  height: 60px;
  background: var(--main-theme-color-two);
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  font-family: sans-serif;
  text-transform: uppercase;
  animation: animate-pulse 3s linear infinite;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes animate-pulse {
  0% {
    box-shadow: var(--main-theme-color-two);
  }

  40% {
    box-shadow: 0 0 0 50px rgba(255, 109, 74, 0),
      0 0 0 0 rgba(255, 109, 74, 0.7);
  }

  80% {
    box-shadow: 0 0 0 50px rgba(255, 109, 74, 0),
      0 0 0 30px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);
  }
}

.around-bg .video-play-btn:before {
  background: var(--main-theme-color-two);
}

.icon-video {
  padding-top: 40px;
}

.icon-video a img {
  width: 20px !important;
  height: 20px;
  margin: 0 auto;
}

#banner-area-four .owl-dots {
  position: absolute;
  top: 0;
  right: -135px;
  bottom: 0;
  margin: 0;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  padding-right: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#banner-area-four .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 10px 7px;
  background: var(--paragraph-color);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

#banner-area-four .owl-theme .owl-dots .owl-dot.active span,
#banner-area-four .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color-one);
}

/* --popular Destion area-- */
#popular-destinition-arae {
  padding: 100px 0;
  background-color: var(--main-theme-color-one);
}

#popular-destinition-arae .main-com {
  margin: 0;
}

#popular-destinition-arae .main-com {
  margin: 0;
  z-index: 999;
}

#popular-destinition-arae .nav-fill {
  width: 30%;
}

#popular-destinition-arae .nav-tabs .nav-link.active {
  color: #fff;
  background-color: var(--main-theme-color-two);
  border-color: transparent;
  border-radius: 0;
}

#popular-destinition-arae .nav-tabs .nav-link:focus,
#popular-destinition-arae .nav-tabs .nav-link:hover {
  border-color: transparent;
}

#popular-destinition-arae select {
  background: #fff;
  padding: 6px 0px 6px 50px;
  height: 45px;
  width: 100%;
  color: var(--main-theme-color-one);
  font-size: 16px;
  border: 1px solid;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 5px;
}

#popular-destinition-arae .nav-tabs .nav-link {
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
}

.ppular-head {
  padding-top: 90px;
}

.ppular-head h2 {
  color: #fff;
}

.ppular-head h2 span {
  color: #f79b36;
}

.popular-deas-slider-item {
  position: relative;
}

.imgarea {
  display: block;
  width: 100%;
  height: auto;
}

.overlay-popular {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #014073b3;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.popular-deas-slider-item:hover .overlay-popular {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.text-area {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-area a {
  font-size: 24px;
  font-weight: 700;
  color: #f79b36;
}

.popular-slider-area {
  padding-top: 30px;
}

.padding-left-65 {
  padding-left: 65px;
}

/* --Counter area-- */
#travel-counter {
  background-image: url(/assets/img/home-03/test-mo-bg.png);
  padding: 100px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

#travel-counter:after {
  position: absolute;
  background-color: #014073b3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
}

.counter-area-text {
  text-align: center;
  z-index: 99;
  position: relative;
}

.counter-area-text h6 {
  color: #fff;
}

.counter-area-text h2 {
  color: #fff;
  padding-top: 20px;
}

.counter-area-text h2 span {
  color: var(--main-theme-color-two);
}

.counter-area-text p {
  color: #fff;
  padding: 10px 200px 0 200px;
}

.counter-area-item {
  text-align: center;
  z-index: 99;
  position: relative;
  padding-top: 70px;
}

.counter-area-item h2 {
  color: #ef8640;
}

.counter-area-item h6 {
  color: #fff;
  padding-top: 10px;
}

/*-------------------------------
      Testimonial area
  -----------------------------------*/
#testimonial-1 {
  padding: 100px 0 100px 0;
}

.text-test-heading-area {
  padding-right: 140px;
}

.text-test-heading-area h2 {
  padding-top: 20px;
}

.text-test-heading-area h2 span {
  color: var(--main-theme-color-two);
}

.testimonial-four-details {
  padding-top: 40px;
}

.testimonial-four-item {
  position: relative;
}

.testimonial-four-item h6 {
  padding-top: 40px;
  font-weight: 700;
}

.rating-star {
  padding-top: 15px;
}

.rating-star i {
  font-size: 16px;
  color: var(--main-theme-color-two);
}

.quote-area {
  position: absolute;
  right: 54px;
  top: 0;
}

.quote-area .flaticon-quotation:before {
  content: "\f105";
  color: var(--main-theme-color-two);
  font-size: 52px;
  position: relative;
  right: 0;
  z-index: 1;
  top: -38px;
  width: 100%;
  left: 0;
}

.client-grapper {
  width: 100px;
}

.client-img-area ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.client-img-area ul li {
  margin: 36px 25px 0px 16px;
}

.client-img-area ul li img {
  cursor: pointer;
}

.t-active {
  box-shadow: 2px 3px 12px var(--main-theme-color-two);
}

/* news letter arae */
#news-letter-arae {
  padding: 100px 0;
  background-color: var(--main-theme-color-one);
}

.input-group .form-control {
  height: 70px;
  padding: 20px;
}

.btn-cta {
  background: #f68d47;
  color: #fff;
  font-size: 18px;
}

.input-area-text {
  padding-right: 80px;
}

.input-area-text h6 {
  color: #fff;
  margin-bottom: 15px;
}

.input-area-text h2 {
  color: #fff;
  margin-bottom: 10px;
}

.input-area-text h2 span {
  color: #ef8640;
}

.input-area-text p {
  color: #fff;
}

/* Blog area */
#blog-four-area {
  padding: 100px 0;
}

.blog-three-img a img {
  width: 100%;
}

.blog-three-details-area {
  padding: 40px 20px 33px 20px;
  position: relative;
  -webkit-box-shadow: 0px 0px 21px -12px #000;
  box-shadow: 0px 0px 21px -12px #000;
  -webkit-transition: 0.4s all;
  -o-transition: 0.4s all;
  transition: 0.4s all;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #fff;
}

.blog-three-details-area:hover {
  border: 1px solid var(--main-theme-color-one);
}

.date-area {
  width: 25%;
  text-align: center;
  padding: 15px 4px;
  -webkit-box-shadow: 0px 6px 4px -8px #000;
  box-shadow: 0px 6px 4px -8px #000;
  position: absolute;
  top: -52px;
  background: var(--main-theme-color-two);
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.blog-area-three:hover .date-area {
  background-color: var(--main-theme-color-one);
}

.date-area h5 {
  color: #fff;
}

.date-area p {
  color: #fff;
  font-weight: 600;
}

.blog-three-details-area h5 a {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.blog-area-three:hover .blog-three-details-area h5 a {
  color: #ef8640;
}

.blog-three-details-area p {
  font-size: 16px;
  line-height: 24px;
}

.admin-area {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-area a {
  font-size: 16px;
  font-weight: 500;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.admin-area a:hover {
  color: #ef8640;
}

.admin-area a img {
  border-radius: 50%;
  border: 2px solid #ef8640;
  height: 50px;
  width: 50px;
  margin-right: 20px;
}

/*----------------------------
     Home Page 05
  --------------------------------*/
#banner-home-five {
  background-image: url(/assets/img/home-05/banner.png);
  padding: 170px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-five-text {
  text-align: center;
}

.banner-five-text h2 {
  color: #fff;
  font-size: 45px;
}

.banner-five-text h2 span {
  display: block;
  margin-top: 15px;
  color: var(--main-theme-color-two);
}

.banner-five-text p {
  color: #fff;
  font-weight: 500;
  padding-top: 20px;
}

#search-box-area .margin-top-minas {
  margin-top: 95px;
}

#banner-five-bottom {
  padding: 100px 0;
}

/* --Top-Destination area -- */
#top-destination {
  padding-bottom: 100px;
}

.top-destination-heading h2 span {
  color: var(--main-theme-color-two);
}

.top-dest-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
}

.top-desti-img {
  overflow: hidden;
  height: 100%;
}

.top-desti-img img {
  transition: 1.3s;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.top-dest-box:hover .top-desti-img img {
  transform: scale(1.1);
  transition: 1.3s;
  overflow: hidden;
}

.top-desti-overlay-box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.top-desti-test-area h4 {
  color: white;
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 20px;
}

/* --Local-area-- */
#local-arae {
  padding: 100px 0;
}

.local-heading h2 span {
  color: var(--main-theme-color-two);
}

.local-item-box {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: 1.5s;
  margin: 20px;
}

.local-item-box:hover {
  box-shadow: 0px 7px 26px -12px #0b4b78;
}

.local-item-img {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}

.local-item-img a img {
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: 1.5s;
}

.local-item-box:hover .local-item-img a img {
  transform: scale(1.1);
}

.local-text-des {
  padding: 20px 15px;
}

.loca-location-area {
  display: flex;
  justify-content: space-between;
}

.loca-location-area p {
  font-size: 14px;
  font-weight: 600;
}

.loca-location-area p:nth-child(2) {
  color: var(--main-theme-color-two);
}

.local-item-heding-para h2 a {
  font-size: 24px;
  font-weight: 600;
}

.local-item-box:hover .local-item-heding-para h2 a {
  color: var(--main-theme-color-two);
}

.local-item-heding-para p {
  font-size: 15px;
}

.local-btn-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.local-rating-box i {
  color: var(--main-theme-color-two);
}

.local-heading {
  margin-bottom: 30px;
}

/* --cta five area-- */
#cat-five-arae {
  background-color: var(--main-theme-color-one);
  padding: 100px 0;
}

.cta-five-text-area h2 {
  color: #fff;
}

.cta-five-text-area h2 span {
  color: var(--main-theme-color-two);
}

.cta-five-text-area p {
  color: #fff;
  padding-top: 10px;
}

.cta-five-button {
  text-align: right;
}

.cta-five-button a {
  border: 2px solid var(--main-theme-color-two);
}

/* --testimonal area-- */
#test-five {
  padding: 100px 0;
}

/* --Search Area-- */
#home-five-searc {
  padding: 100px 0;
  background-color: var(--main-theme-color-one);
}

.newa-letter-area-five-text h4 {
  font-size: 20px;
  color: #fff;
}

.newa-letter-area-five-text h2 {
  color: #fff;
  padding: 10px 0;
}

.newa-letter-area-five-text h2 span {
  color: var(--main-theme-color-two);
}

.newa-letter-area-five-text p {
  color: #fff;
}

.input-group-btn a {
  border: 2px solid var(--main-theme-color-two);
  line-height: 45px;
}

/* --Blog Area-- */
#blog-five-area {
  padding: 100px 0;
}

.blog-five-box {
  margin-bottom: 30px;
}

.blog-five-img {
  overflow: hidden;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blog-five-img a img {
  width: 100%;
  transition: 0.4s all ease-in;
  -webkit-transition: 0.4s all ease-in;
  -moz-transition: 0.4s all ease-in;
  -ms-transition: 0.4s all ease-in;
  -o-transition: 0.4s all ease-in;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blog-five-box:hover .blog-five-img a img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.blog-five-details-area {
  padding: 30px 20px;
  box-shadow: 0px 0px 0px 0.6px #d1dfe8;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: 0.4s all ease-in;
  -webkit-transition: 0.4s all ease-in;
  -moz-transition: 0.4s all ease-in;
  -ms-transition: 0.4s all ease-in;
  -o-transition: 0.4s all ease-in;
}

.blog-five-box:hover .blog-five-details-area {
  box-shadow: 0px 0px 15px -7px #014073;
}

.blog-time-author-arae ul {
  display: flex;
  justify-content: space-between;
}

.blog-time-author-arae ul li {
  font-size: 15px;
  color: var(--paragraph-color);
}

.blog-time-author-arae ul li a {
  color: var(--paragraph-color);
}

.blog-time-author-arae ul li a:hover {
  color: var(--main-theme-color-two);
}

.blog-five-heading h3 {
  padding-top: 20px;
}

.blog-five-heading h3 a {
  font-size: 24px;
  font-weight: 600;
}

.blog-five-box:hover .blog-five-heading h3 a {
  color: var(--main-theme-color-two);
}

.blog-five-heading p {
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
}

.blog-five-button-area {
  margin-top: 25px;
}

.blog-five-button-area a {
  font-size: 16px;
  border: 1px solid var(--main-theme-color-one);
  padding: 10px 20px;
  color: var(--main-theme-color-one);
  border-radius: 4px;
  display: inline-flex;
  transition: 0.4s all ease-in;
  -webkit-transition: 0.4s all ease-in;
  -moz-transition: 0.4s all ease-in;
  -ms-transition: 0.4s all ease-in;
  -o-transition: 0.4s all ease-in;
}

.blog-five-button-area a:hover {
  border: 1px solid var(--main-theme-color-two);
  color: var(--main-theme-color-two);
}

/*----------------------------
     Home Page 6
  --------------------------------*/

/* --search-box-area-- */
.search-box-area-redesign {
  box-shadow: 0px 0px 30px -14px #000;
  border-radius: 10px;
  margin-top: -70px;
  background: #fff;
}

#search-box-area form {
  display: flex;
}

#search-box-area .input-group .form-control {
  height: 40px;
  padding: 0px;
}

#search-box-area form input {
  background: transparent;
  border: none;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

#search-box-area form input::placeholder {
  color: var(--main-theme-color-two);
}

#search-box-area form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--main-theme-color-two);
}

#search-box-area form input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--main-theme-color-two);
}

.area-form-wrapper {
  display: flex;
}

#datepicker-one,
#datepicker-two {
  width: 60%;
}

.form-flex-area {
  padding: 40px;
  border-right: 1px solid #cccccc;
  width: 25%;
}

.form-flex-area label {
  font-size: 14px;
  color: var(--main-theme-color-one);
  font-weight: 600;
  margin-left: 0;
}

.form-flex-area i {
  padding-right: 8px;
}

.area-box-select select {
  font-size: 14px;
  background: transparent;
  padding: 10px 0;
  color: var(--main-theme-color-two);
  width: 20%;
}

.area-box-select i {
  color: var(--main-theme-color-two);
}

.button-area-form-box {
  width: 32%;
  background: var(--main-theme-color-two);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.button-area-form-box:hover {
  background-color: var(--main-theme-color-one);
}

.button-area-form-box a {
  color: #fff;
  font-size: 16px;
}

.button-area-form-box a i {
  color: #fff;
  padding-right: 5px;
}

/* -- select your destination -- */

#select-destination-six {
  padding: 100px 0;
}

.destnation-img-box {
  overflow: hidden;
}

.destnation-img-box a img {
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.destnation-img-box a img:hover {
  transform: scale(1.1);
  opacity: 0.9;
}

.destnation-details-box {
  background: #fff;
  margin: 0 20px;
  padding: 10px 17px;
  box-shadow: 0px 0px 15px -7px var(--main-theme-color-one);
  margin-top: -55px;
  position: relative;
}

.top-destinatio-icon-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-destination i {
  color: var(--main-theme-color-two);
}

.offer-area p {
  font-size: 16px;
  font-weight: 700;
  color: var(--main-theme-color-two);
}

.destination-area-name h3 {
  padding-top: 10px;
}

.destination-area-name h3 a {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: #272063;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.destnation-details-box:hover .destination-area-name h3 a {
  color: var(--main-theme-color-two);
}

.destnation-box-area {
  margin: 20px;
}

#select-destination-six .owl-theme .owl-dots .owl-dot.active span {
  background: var(--main-theme-color-one);
}

#select-destination-six .owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 5px 7px;
  background: var(--main-theme-color-two);
}

#select-destination-six .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color-one);
}

#select-destination-six .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 40px;
}

/* --Perfect Destination -- */
#perfect-destination {
  padding: 100px 0;
}

.perfect-box-area {
  margin: 25px 25px;
}

.perfect-box-img {
  overflow: hidden;
}

.perfect-box-img a img {
  width: 100%;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.perfect-box-img a img:hover {
  transform: scale(1.1);
  opacity: 0.9;
}

.perfect-box-details {
  box-shadow: 0px 0px 16px -7px var(--main-theme-color-one);
}

.perfect-top-details {
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 0 20px;
  margin-top: -20px;
  position: relative;
  padding: 15px 10px;
}

.perfect-area-location p {
  font-size: 16px;
  font-weight: 700;
}

.perfect-area-location p i {
  color: var(--main-theme-color-one);
  padding-right: 5px;
}

.perfect-area-calender p {
  font-size: 16px;
  font-weight: 700;
}

.perfect-area-calender p i {
  color: var(--main-theme-color-one);
  padding-right: 5px;
}

.perfect-details-text {
  padding: 9px 15px 20px 15px;
}

.perfect-details-text h6 {
  padding-top: 10px;
}

.perfect-details-text h6 a {
  font-weight: 600;
}

.perfect-details-text p {
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
}

.book-area-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.perfect-book-star i {
  color: var(--main-theme-color-two);
  padding-right: 3px;
}

.perfect-slider-wrapper-2.owl-theme .owl-dots .owl-dot.active span {
  background: var(--main-theme-color-one);
}

.perfect-slider-wrapper-2.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 5px 7px;
  background: var(--main-theme-color-two);
}

.perfect-slider-wrapper-2.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color-one);
}

.perfect-slider-wrapper-2.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

/* --video area-- */

#video-area-motion {
  position: relative;
  padding: 0 0 100px 0;
}

.guid-area {
  display: flex;
  justify-content: space-between;
}

.guid-svg-img {
  width: 35%;
  margin-right: 18px;
}

.guid-svg-img img {
  max-width: 100%;
  height: auto;
}

.guid-details h6 {
  font-weight: 600;
}

.guid-details p {
  font-size: 14px;
  padding-top: 10px;
  line-height: 24px;
}

#video-area-motion .row:nth-child(2) {
  padding-top: 100px;
}

/* --Testimonial area-- */

#test-six {
  padding: 100px 0;
  background-image: url(/assets/img/home-06/test.png);
  background-size: cover;
  position: relative;
}

#test-six::before {
  content: "";
  background-color: #01407399;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.test-six-box {
  background-color: #fff;
  box-shadow: 0px 0px 18px -10px #014073;
  margin: 10px;
}

.test-six-top-area {
  display: flex;
  align-items: center;
  padding: 30px 30px 25px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

.test-six-name {
  margin-left: 20px;
}

.test-six-name h5 {
  font-weight: 600;
}

.test-six-name p {
  font-size: 16px;
  font-weight: 500;
}

.test-six-details {
  padding: 30px 10px 0 30px;
}

.test-six-details p {
  font-size: 16px;
  font-weight: 400;
}

.test-six-rating {
  padding: 30px 35px 25px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-rating i {
  color: var(--main-theme-color-two);
  font-size: 16px;
}

#test-six .owl-theme .owl-dots .owl-dot.active span,
#test-six .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color-two);
}

#test-six .owl-theme .owl-dots .owl-dot.active span {
  width: 30px;
}

#test-six .owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 11px;
  margin: 0px 10px;
  background: #fff;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

#test-six .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 50px;
}

/* --Blog-Six-area-- */
#blog-six-area {
  padding: 100px 0;
}

.blog-related-side {
  position: relative;
}

.related-area-overlay {
  background: #014073a6;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 25px 20px;
}

.related-details-text {
  position: absolute;
  bottom: 0;
  padding: 25px 20px;
  left: 0;
}

.related-details-text h5 a {
  color: var(--main-theme-color-two);
  font-weight: 600;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.img-related-cover {
  width: 100%;
}

.related-details-text h5 a:hover {
  color: #eaeaea;
}

.related-details-text p {
  color: #eaeaea;
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
  padding-right: 80px;
}

.bottom-img-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 35px;
}

.bottom-img-related {
  display: flex;
  align-items: center;
}

.bottom-img-related p {
  padding: 0;
  padding-left: 15px;
}

.bottom-img-related p a {
  color: #eaeaea;
  font-size: 16px;
  font-weight: 600;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.bottom-img-related p a:hover {
  color: var(--main-theme-color-two);
}

.bottom-date-related p {
  padding: 0;
}

.bottom-date-related p a {
  color: #eaeaea;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.bottom-date-related p a:hover {
  color: var(--main-theme-color-two);
}

.blog-six-item {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.blog-six-item-area .blog-six-item:last-child {
  margin-bottom: 0;
}

.blog-six-item-img a img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.blog-six-item-text {
  padding-left: 25px;
}

.blog-six-item-text h5 {
  line-height: 35px;
}

.blog-six-item-text h5 a {
  font-weight: 500;
}

.blog-six-item-text h5 a:hover {
  color: var(--main-theme-color-two);
}

.blog-six-author-arae-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.author-item-blog-six a {
  color: var(--paragraph-color);
  font-size: 16px;
  padding-right: 11px;
}

.author-item-blog-six a:hover,
.author-item-blog-six-date a:hover {
  color: var(--main-theme-color-two);
}

.author-item-blog-six-date a {
  color: var(--paragraph-color);
  font-size: 16px;
  padding-right: 11px;
}

/*----------------------------
       Back to top Button
  --------------------------------*/

.go-top.active {
  top: 98%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}

.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: var(--main-theme-color-one);
  z-index: 999;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  border-radius: 50%;
}

.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--main-theme-color-two);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
}

.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}

.go-top:hover,
.go-top:focus {
  color: #ffffff;
}

.go-top:hover::before,
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.form-control {
  height: 55px;
  border: 2px dashed #dddddd75;
  padding: 10px 22px;
  font-size: 16px;
  box-shadow: 0px 1px 13px 0px #0000000d;
}

.common_author_boxed {
  background: #f3f6fd;
  padding: 45px 20px;
}

.common_author_heading {
  text-align: center;
}

.common_author_heading h3 {
  font-weight: 400;
  color: var(--paragraph-color);
}

.common_author_heading h2 {
  padding-top: 15px;
}

.common_author_form {
  padding: 50px 100px 0px 100px;
}

#main_author_form .form-group {
  margin-bottom: 20px;
  text-align: right;
}

#main_author_form .form-group a {
  color: var(--paragraph-color);
  padding-top: 10px;
  display: block;
}

#main_author_form .form-group a:hover {
  color: var(--main-color);
}

.common_form_submit {
  text-align: center;
}

.common_form_submit {
  padding-top: 20px;
}

.have_acount_area {
  text-align: center;
  padding-top: 20px;
}

.other_author_option ul {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.other_author_option ul li {
  padding-left: 10px;
  transition: var(--transition);
}

.other_author_option ul li:hover {
  transform: translateY(-10px);
}

.line_or {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 30px;
}

.line_or span {
  display: block;
  background: #f3f6fd;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: var(--black-color);
  font-size: 20px;
  margin: 0px auto -21px auto;
}

.otpCont {
  text-align: center;
  display: flex;
  justify-content: center;
}

.otSc {
  margin: 0;
  margin-right: 34px;
  border: 1px solid var(--main-color);
  padding: 5px 0px;
  font-size: 22px;
  text-align: center;
  width: 7%;
  outline: none;
}

.otSc:last-child {
  margin-right: 0;
}
a {
  text-decoration: none !important;
}
#banner-home-slider .owl-next span,
#banner-home-slider .owl-prev span {
  line-height: 18px;
  display: block;
}
#banner-home-slider .button-common {
  position: absolute;
  right: 5%;
  bottom: 15%;
}
.date-field {
  position: absolute;
  .react-datepicker-wrapper {
    z-index: 2;
  }
  .fa {
    z-index: 0;
    position: absolute;
    right: 10px;
    bottom: 20px;
  }

  .react-datepicker__calendar-icon {
    margin-top: 5%;
  }
}
.step-form {
  h3 {
    // color: var(--main-theme-color-two);
    position: relative;
    float: left;
    clear: both;
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      height: 2px;
      width: 100%;
      background: var(--main-theme-color-two);
      left: 0;
    }
  }
}

.d-control > div {
  width: 100%;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.d-steps {
  margin-bottom: 30px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}
.slider-des .slick-list {
  margin-right: -10px;
}
.slick-prev:before,
.slick-next:before {
  font: normal normal normal 14px/1 FontAwesome !important;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  background: var(--main-theme-color-two);
}
.slick-prev:before {
  content: "\f060" !important;
}
.slick-next:before {
  content: "\f061" !important;
}

.error-message {
  color: red;
  font-size: 14px;
}
