.landing-component {
  .carousel {
    .carousel-item {
      img {
        height: 50vh;
        width: 100%;
      }
    }
  }
  .enquire-me {
    position: absolute;
    right: 2rem;
    z-index: 999;
    bottom: 1rem;
  }

  .category-chip {
    border: solid 1px #eee;
    border-radius: 20px;
    margin-right: 5px;
  }

  .carasol-floating-text {
    padding: 20px;
    background-color: rgba(1, 56, 100, 0.5);
    color: white;
    border-left: var(--main-theme-color-two) 10px solid;
  }

  .card {
    border-radius: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .card-header-style {
    background: var(--main-theme-color-one);
    color: white;
  }
  .video-container {
    // box-shadow: 0px 0px 12px -10px #000;
    .video-style {
      width: 100%;
      height: 168px;
    }
    .card-body-custom {
      background-color: ghostwhite;
    }
  }

  #domestic-trips {
    background-color: #f5f5f5;
    .card-image {
      height: 200px;
      width: 100%;
    }
  }
}
