.dashboard_sidebar {
    background: #FFFFFF;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.dashboard_sidebar_user {
    text-align: center;
    padding: 30px 20px;
    background: #F3F6FD;
}

.dashboard_sidebar_user img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
}

.dashboard_sidebar_user h3 {
    padding-top: 20px;
    font-weight: 500;
}

.dashboard_sidebar_user p {
    line-height: 17px;
    padding-top: 10px;
}

.dashboard_sidebar_user p a {
    color: var(--paragraph-color);
}

.dashboard_sidebar_user p a:hover {
    color: var(--main-color);
}

.dashboard_menu_area {
    padding: 40px 20px;
}

.dashboard_menu_area ul li {
    margin-bottom: 30px;
    font-size: 18px;
}

.dashboard_menu_area ul li:last-child {
    margin-bottom: 0px;
}

.dashboard_menu_area ul li:hover {
    color: var(--main-color);
    cursor: pointer;
}

.dashboard_menu_area ul li a {
    color: var(--black-color);
}

.dashboard_menu_area ul li a.active {
    color: var(--main-color);
}

.dashboard_menu_area ul li a:hover {
    color: var(--main-color);
}

.dashboard_menu_area ul li i {
    padding-right: 10px;
}

.booing_sidebar_dashboard {
    padding-top: 30px;
    padding-left: 30px;
}

.dashboard_dropdown_button {
    position: relative;
}

.dashboard_dropdown_button span {
    position: absolute;
    right: 0;
    display: block;
    top: 0;
}

.dashboard_top_boxed {
    background: #FFFFFF;
    box-shadow: -4px -4px 14px rgba(0, 0, 0, 0.08), 8px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 25px 40px;
    display: flex;
    align-items: center;
}

.dashboard_main_top {
    margin-bottom: 30px;
}

.dashboard_top_icon i {
    font-size: 100px;
    color: var(--paragraph-color);
}

.dashboard_top_text {
    padding-left: 20px;
}

.dashboard_top_text h3 {
    padding-bottom: 8px;
    font-weight: 500;
    color: var(--paragraph-color);
}

.dashboard_top_text h1 {
    line-height: 64px;
    font-size: 70px;
    font-weight: 500;
}

.dashboard_common_table {
    background: #F3F6FD;
    border-radius: 12px;
    padding: 25px 30px;
}

.dashboard_common_table h3 {
    font-weight: 500;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 11px;
    position: relative;
}

.dashboard_common_table h3::after {
    content: "";
    width: 140px;
    height: 2px;
    background: var(--main-color);
    position: absolute;
    left: 0;
    bottom: 0;
}

.table_common_area {
    margin-top: 40px;
}

.table_common_area table {
    text-align: center;
    border: 1px solid #d5d5d5;
}

.table_common_area thead tr th {
    border: none;
    background: #fff;
    padding: 15px 0;
}

.table_common_area tbody tr td {
    padding: 16px 0;
}

.table_common_area tbody tr td.complete {
    color: #4CAF50;
    font-weight: 500;
}

.table_common_area tbody tr td.cancele {
    color: #C8102E;
    font-weight: 500;
}

.table_common_area tbody tr td i {
    cursor: pointer;
}

/* ---My Profile Page--- */
#profile_form_area {
    padding-top: 35px;
    clear: both;
}

#profile_form_area .form-group {
    margin-bottom: 30px;
}

#profile_form_area label {
    margin-bottom: 10px;
}

#profile_form_area .change_password_field {
    position: relative;
}

#profile_form_area .change_password_field p {
    position: absolute;
    bottom: 14px;
    right: 15px;
    font-size: 12px;
    cursor: pointer;
    color: var(--main-color);
    font-weight: 500;
}

.change_password_input_boxed h3 {
    margin-bottom: 30px;
}

.notification_top_heading {
    position: relative;
}

.notification_top_heading p {
    color: var(--main-color);
    padding-left: 20px;
    position: absolute;
    left: 147px;
    top: 0;
    font-weight: 600;
    font-size: 12px;
}

.notification_wrapper {
    padding-top: 30px;
}

.notification_wrapper .accordion-item {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 15px;
}

.notification_wrapper .accordion-button {
    padding: 4px 25px;
    font-size: 16px;
    background-color: transparent;
}

.notification_wrapper .accordion-button.active {
    background: var(--white-color);
}

.notification_wrapper .accordion-button.shows {
    background: var(--white-color);
}

.notification_wrapper .accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
}

.notification_wrapper .accordion-button::after {
    display: none;
}

.modal {
    z-index: 99999;
}

.logout_modal_content {
    text-align: center;
    padding: 80px 0px 40px 0;
}

.logout_modal_content h3 {
    font-weight: 400;
    font-size: 35px;
    line-height: 45px;
}

.logout_approve_button {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px 0 30px;
}

.logout_approve_button button {
    width: 46%;
}

.btn_border {
    border: 1px solid var(--main-color);
}

.btn_border:hover {
    background: var(--main-color);
}